<template>
  <pos-layout>
    <template #navbar>
      <span>&nbsp;</span>
    </template>

    <div class="h-100 orders d-print-none">
      <!-- Print panel For mobile -->
      <div class="print d-md-none">
        <b-row class="h-100 no-gutters">
          <b-col
            cols="3"
            class="h-100 d-md-none"
          >
            <b-button
              :to="{ name: 'Tables', params: { id: currentOrder.table }}"
              variant="link"
              class="w-100 bg-pink"
            >
              <svg class="icon icon-icon-table">
                <use xlink:href="#icon-icon-table" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-to-tables-mob") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            cols="3"
            class="h-100"
          >
            <b-button
              :class="{'btn-disabled': itemsAtCurrentOrderLength === 0}"
              variant="link"
              class="w-100"
              @click="printCheck()"
              @mousedown="start"
              @mouseleave="stop"
              @mouseup="stop"
              @touchstart="start"
              @touchend="stop"
              @touchcancel="stop"
            >
              <svg class="icon icon-icon-print-1">
                <use xlink:href="#icon-icon-print-1" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-check") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            cols="3"
            class="h-100"
          >
            <b-button
              variant="link"
              class="w-100 btn-disabled"
            >
              <svg class="icon icon-icon-print-2">
                <use xlink:href="#icon-icon-print-2" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.kitchen-print") }}
              </span>
            </b-button>
          </b-col>
          <b-col
            cols="3"
            class="h-100 d-none d-md-block"
          >
            <b-button
              variant="link"
              class="w-100 btn-disabled"
            >
              <svg class="icon icon-icon-print-3">
                <use xlink:href="#icon-icon-print-3" />
              </svg>

              {{ $t("amountandpaymentmethod.open-drawer") }}
            </b-button>
          </b-col>
          <b-col
            cols="3"
            class="h-100 d-none d-md-block"
          >
            <b-button
              variant="link"
              class="w-100"
            >
              <svg class="icon icon-icon-print-4">
                <use xlink:href="#icon-icon-print-4" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.credit-card") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            v-if="!sidebarMob"
            cols="3"
            class="h-100 d-md-none"
          >
            <b-button
              variant="link"
              class="w-100 bg-primary"
              @click="sidebarMob = !sidebarMob"
            >
              <svg class="icon icon-icon-mob-add">
                <use xlink:href="#icon-icon-mob-add" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.payment-options") }}
              </span>
            </b-button>
          </b-col>

          <b-col
            v-if="sidebarMob"
            cols="3"
            class="h-100 d-md-none"
          >
            <b-button
              variant="link"
              class="w-100 bg-info"
              @click="sidebarMob = !sidebarMob"
            >
              <svg class="icon icon-icon-mob-view">
                <use xlink:href="#icon-icon-mob-view" />
              </svg>

              <span>
                {{ $t("amountandpaymentmethod.print-view-order-mob") }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </div>


      <b-row class="h-100 h-sm-100 no-gutters">
        <b-col
          :class="{hide: sidebarMob}"
          col
          cols="12"
          md="5"
          lg="4"
          class="h-100 orders-sidebar-mob"
        >
          <div
            :class="{ 'orders-sidebar-kiosk': isKioskMode }"
            class="orders-sidebar"
          >
            <div class="orders-head">
              <div
                :class="{ 'bg-primary': isKioskMode }"
                class="orders-top"
              >
                <div class="d-flex align-items-center">
                  <div class="orders-top-num">
                    <span>
                      {{ $t("amountandpaymentmethod.order-no") }}
                    </span>
                    <strong># {{ (currentOrder.id || currentOrder.local_id) }}</strong>
                  </div>

                  <div
                    v-if="isKioskMode"
                    class="orders-top-num ml-auto"
                  >
                    <span>Order Type</span>
                    <strong>{{ orderDiningOption ? orderDiningOption.name : "Undefined" }}</strong>
                  </div>

                  <div class="orders-top-btn ml-auto d-flex align-items-center">
                    <b-button
                      :class="{ 'd-none': isKioskMode }"
                      :to="{ name: 'Order', params: { id: currentOrder.id?currentOrder.id:currentOrder.local_id }}"
                      variant="dark"
                    >
                      {{ $t("amountandpaymentmethod.edit-order") }}
                    </b-button>

                    <b-button
                      variant="secondary"
                      @click="cancelOrder()"
                    >
                      {{ $t("orderscreen.cancel-order") }}
                    </b-button>
                  </div>
                </div>
              </div>
              <div
                v-if="isKioskMode"
                class="orders-list-th"
              >
                <div class="d-flex align-items-center h-100 justify-content-between">
                  <div class="th">
                    QTY
                  </div>
                  <div class="th">
                    PRODUCT NAME
                  </div>
                  <div class="th ml-auto">
                    PRICE
                  </div>
                </div>
              </div>
              <div
                v-if="!isKioskMode"
                class="orders-add"
              >
                <b-button
                  variant="link"
                  @click="showSelectCustomerModal"
                >
                  <div v-if="!currentOrder.customer">
                    <svg class="icon icon-icon-add">
                      <use xlink:href="#icon-icon-add" />
                    </svg>

                    <span>
                      {{ $t("orderscreen.add-customer") }}
                    </span>
                  </div>
                  <div v-else>
                    {{ currentOrder.customer.first_name }} {{ currentOrder.customer.last_name }}
                  </div>
                </b-button>
              </div>
            </div>

            <div class="orders-sidebar-body ">
              <div
                :class="{ 'orders-list-kiosk': isKioskMode }"
                class="orders-list"
                role="tablist"
              >
                <div
                  v-if="!itemsAtCurrentOrder.length && isKioskMode"
                  class="orders-empty-kiosk"
                >
                  <h5>
                    Your card is empty
                  </h5>
                  <p>
                    Please, add items to get started.
                  </p>

                  <img
                    src="../assets/images/powered.svg"
                    class="kiosk-powered"
                  >
                </div>

                <div v-if="!isKioskMode">
                  <b-card
                    v-for="(item, index) in itemsAtCurrentOrder"
                    :class="{'sent_to_kitchen': item.sent_to_kitchen}"
                    :key="index"
                    no-body
                  >
                    <b-card-header
                      header-tag="header"
                      class="orders-item-head p-0"
                      role="tab"
                    >
                      <b-button
                        v-b-toggle="'accordion-'+index"
                        block
                        href="#"
                      >
                        <div class="d-flex">
                          <div class="btn-num">
                            {{ item.quantity }}
                          </div>

                          <div class="btn-body">
                            <div class="d-flex align-items-center justify-content-between top">
                              <div class="text-left">
                                {{ item.name }}
                              </div>
                              <div class="text-right">
                                {{ currency }} {{
                                  getItemGrandTotal(item) ? getItemGrandTotal(item).toFixed(2) : "0.00"
                                }}
                              </div>
                            </div>
                            <div
                              v-if="item.discount > 0"
                              class="d-flex align-items-center justify-content-between sub"
                            >
                              <div class="text-left">
                                {{ currency }} {{ $t("amountandpaymentmethod.discount") }}
                              </div>
                              <div class="text-right">
                                {{ item.discount.toFixed(2) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-'+index"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="sub-accordion"
                    >
                      <b-card-body>
                        <div
                          v-if="item.discount > 0"
                          class="d-flex align-items-center justify-content-between sub-discount text-nowrap"
                        >
                          <div class="text-left">
                            {{ currency }} {{ $t("orderscreen.discount") }}
                          </div>
                          <div class="text-right">
                            {{ currency }} {{ (item.discount * item.quantity).toFixed(2) }}
                          </div>
                        </div>
                        <div
                          v-for="modifierGroup in getModifiersByItemPosition(item.id)"
                          :key="modifierGroup.id"
                          class="mod"
                        >
                          <div class="d-flex align-items-center justify-content-between sub text-nowrap">
                            <div class="text-left mod-name">
                              {{ modifierGroup.name }}
                            </div>
                            <div class="text-right" />
                          </div>
                          <div
                            v-for="modifier of modifierGroup.modifiers"
                            :key="modifier.id"
                            class="d-flex align-items-center justify-content-between sub text-nowrap"
                          >
                            <div class="text-left">
                              {{ modifier.name }}
                            </div>
                            <div class="text-right">
                              {{ currency }} {{ (item.quantity * modifier.price).toFixed(2) }}
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <!-- <b-card 
                    v-for="(card, index) in giftCardPaymentsAtCurrentOrder" 
                    :key="'gift-card-'+index"
                    no-body
                    class="list-complete-item"
                  >
            <b-card-header
              header-tag="header"
              class="orders-item-head p-0"
              role="tab"
            >
              <b-button
                v-b-toggle="'accordion-' + index"
                block
                href="#"
              >
                <div class="d-flex">
                  <div class="btn-num">
                    1
                  </div>

                  <div class="btn-body">
                    <div class="d-flex align-items-center justify-content-between align-items-center text-nowrap">
                      <div class="text-left">
                       Gift Card
                        <p class="mt-2 text-muted f-small ">Card Number: {{card.gift_card_number}}</p>
                      </div>
                      <div class="text-right align-self-center d-flex flex-column justify-content-start">
                      <div class="">
                        {{ currency }} {{ card.amount_paid }}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </b-button>
            </b-card-header>  
          </b-card> -->
                </div>

                <div v-if="isKioskMode">
                  <b-card
                    v-for="item in itemsAtCurrentOrder"
                    :key="item.id"
                    variant="link"
                    no-body
                    class="list-complete-item"
                    @click="changeQuantity(item.id)"
                  >
                    <div class="card-header p-0">
                      <div class="d-flex align-items-center justify-content-between">
                        <b-button
                          class="card-qnt"
                          variant="link"
                        >
                          <span class="bg-primary text-white">
                            {{ item.quantity }}
                          </span>
                        </b-button>

                        <h6 class="card-title mb-0">
                          {{ item.name }}
                        </h6>

                        <div class="card-price ml-auto">
                          <span
                            v-if="item.tax > 0 && item.tax_status === 0 && !isKioskMode"
                            class="short-desc"
                          >{{ $t("listvieworderdelegate.taxed") }}</span>
                          <span>
                            {{ currency }} {{ getItemPriceDisplay(item) }}
                          </span>
                        </div>

                        <b-button
                          variant="link"
                          class="btn-remove ml-1"
                          @click.stop.prevent="removeItemFromOrder(item.id)"
                        >
                          <svg class="icon icon-icon-remove">
                            <use xlink:href="#icon-icon-remove" />
                          </svg>
                        </b-button>
                      </div>

                      <div
                        v-for="modifierGroup in getModifiersByItemPosition(item.id)"
                        :key="modifierGroup.id"
                        class="mod"
                      >
                        <div class="d-flex align-items-center justify-content-between sub text-nowrap">
                          <div class="text-left mod-name">
                            {{ modifierGroup.name }}
                          </div>
                          <div class="text-right" />
                        </div>
                        <div
                          v-for="modifier of modifierGroup.modifiers"
                          :key="modifier.id"
                          class="d-flex align-items-center justify-content-between sub text-nowrap"
                        >
                          <div class="text-left">
                            {{ modifier.name }}
                          </div>
                          <div class="text-right">
                            {{ currency }} {{ (item.quantity * modifier.price).toFixed(2) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </div>

                <div v-if="itemsAtCurrentOrder.length && isKioskMode">
                  <b-card
                    no-body
                    class="list-complete-item card-empty"
                  >
                    <b-button
                      :to="{ name: 'Order', params: { id: (currentOrder.id || currentOrder.local_id), tableID: null }}"
                    >
                      <span class="icon icon-plus" />
                      Add additional items
                    </b-button>
                  </b-card>
                </div>
              </div>
            </div>

            <div class="orders-bottom">
              <div v-if="!isDisplayPaymentDetails">
                <div class="orders-bottom-item">
                  <span>{{ $t("amountandpaymentmethod.total") }}</span>
                  <span class="text-right">
                    {{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
                    <b-button
                      variant="link"
                      class="w-100"
                      @click="displayPaymentDetails"
                    >
                      {{ $t("ordertotal.view-details") }}
                    </b-button>
                  </span>
                </div>
              </div>
              <div v-else>
                <div class="orders-bottom-item">
                  <b-button
                    variant="link"
                    class="w-100 text-center"
                    @click="displayPaymentDetails"
                  >
                    {{ $t("ordertotal.close-details") }}
                  </b-button>
                </div>
                <div class="orders-bottom-item">
                  <span>
                    {{ $t("amountandpaymentmethod.discount") }}
                  </span>
                  <span>{{ currency }} {{ currentOrder.discount_total.toFixed(2) }}</span>
                </div>

                <div class="orders-bottom-item">
                  <span>
                    {{ $t("amountandpaymentmethod.sub-total") }}
                  </span>
                  <span>{{ currency }} {{ currentOrder.subtotal.toFixed(2) }}</span>
                </div>

                <div class="orders-bottom-item">
                  <span>
                    {{ $t("amountandpaymentmethod.tax") }}</span>
                  <span>{{ currency }} {{ currentOrder ? currentOrder.tax_total.toFixed(2) : "0.00" }}</span>
                </div>

                <div
                  class="orders-bottom-item"
                  v-if="currentOrder.tips_total"
                >
                  <span>
                    {{ $t("ordertotal.tip") }}
                  </span>
                  <span>
                    {{ currency }} {{ currentOrder.tips_total.toFixed(2) }}
                  </span>
                </div>

                <div class="orders-bottom-item">
                  <span>{{ $t("amountandpaymentmethod.total") }}</span>
                  <span>{{ currency }} {{ currentOrder.grand_total ? currentOrder.grand_total.toFixed(2) : "0.00" }}
                  </span>
                </div>
                <div
                  v-for="(payment, index) in currentOrder.payments"
                  :key="index"
                  class="orders-bottom-item"
                >
                  {{ $t("ordertotal.paid") }} - {{ payment.payment_type }}
                  <span>
                    {{ currency }} {{ payment.amount.toFixed(2) }}
                    <span :style="{color: 'red'}">
                      {{ `(${payment.change_amount.toFixed(2)})` }}
                    </span>
                  </span>
                </div>
              </div>
              <div class="orders-bottom-options">
                <div class="pay pay-total">
                  <div class="d-flex align-items-center justify-content-between">
                    <span
                      v-if="!isKioskMode"
                      class="text-left"
                    >
                      {{ $t("amountandpaymentmethod.balance") }}
                    </span>
                    <span
                      v-if="isKioskMode"
                      class="text-left"
                    >
                      {{ $t("amountandpaymentmethod.total") }}
                    </span>
                    <span class="text-right">
                      {{ currency }} {{ currentOrderGrandTotalSum.toFixed(2) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col
          col
          cols="12"
          md="7"
          lg="8"
          class="h-100 orders-col checkout-col"
        >
          <div class="h-100 checkout-container">
            <div
              class="checkout-wrapper d-md-flex"
              :class="isKioskMode ? 'justify-content-center' : 'justify-content-between'"
            >
              <div
                :class="{ 'checkout-kiosk': isKioskMode }"
                class="checkout h-100"
              >
                <div class="checkout-head text-center">
                  <h5
                    v-if="!isKioskMode"
                    class="checkout-title"
                  >
                    {{ $t("amountandpaymentmethod.amount-tendered") }}
                  </h5>
                </div>

                <div
                  class="checkout-body"
                >
                  <h5
                    v-if="isKioskMode"
                    class="checkout-title"
                  >
                    Amount to be Paid
                  </h5>

                  <div class="calc">
                    <div class="calc-win">
                      {{ amountTendered.toFixed(2) }}
                    </div>

                    <div
                      v-if="isKioskMode"
                      class="calc-body mb-4 mb-lg-0"
                    >
                      <h5 class="checkout-title">
                        How would you like to pay?
                      </h5>
                      <div class="calc-control1">
                        <!--                    do we need yellow button?-->
                        <!--                    <b-button-->
                        <!--                      v-if="terminal.payment_types.find(p => p.name === 'Cash')"-->
                        <!--                      variant="warning"-->
                        <!--                      @click="!isPaymentProcessing?pay():null">-->
                        <!--                      Pay at the Register-->
                        <!--                    </b-button>-->
                        <div
                          v-for="(paymentType, index) in paymentTypes"
                          :key="index"
                        >
                          <b-button
                            variant="primary"
                            @click="!isPaymentProcessing?pay(paymentType):null"
                            class="disabled"
                          >
                            Pay with {{ paymentType.name }}
                          </b-button>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="!isKioskMode"
                      class="calc-body mb-4 mb-lg-0"
                    >
                      <div class="calc-pin">
                        <SimpleNumKeyboard
                          :withconfirm="false"
                          :type="0"
                          :amount="amountTenderedString"
                          @updated="typeNum"
                        />

                        <h5 class="checkout-title w-100">
                          {{ $t("amountandpaymentmethod.quick-cash-payment") }}
                        </h5>
                      </div>

                      <div class="calc-control d-lg-none">
                        <b-button
                          v-for="(paymentType, index) in paymentTypes"
                          :key="index"
                          :class="[(amountTendered < 0.01 && !refundOrderId) || isPaymentProcessing? 'disabled' : '', paymentType.name.toString().toLowerCase().split(' ').join('')]"
                          variant="dark"
                          @click="!isPaymentProcessing?pay(paymentType):null"
                        >
                          {{ paymentType.name }}
                        </b-button>
                      </div>
                    </div>

                    <div
                      v-if="!isKioskMode"
                      class="qash"
                    >
                      <div class="d-flex flex-wrap align-items-center justify-content-between">
                        <div
                          v-for="(amount, index) in quickCash"
                          :key="index"
                          :class="'calc-set-'+index"
                          class="calc-set"
                          @click="typeCustom(amount)"
                        >
                          {{ amount }}
                        </div>

                        <b-button
                          v-if="currentOrder.table"
                          class="btn btn-primary"
                          @click="goToTableOrders()"
                        >
                          <span>
                            {{ $t("orderscreen.split-bill") }}
                          </span>
                        </b-button>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="!isKioskMode"
                    class="print d-none d-md-block"
                  >
                    <b-row class="h-100 no-gutters">
                      <b-col
                        :v-if="false"
                        cols="4"
                        class="h-100"
                      >
                        <b-button
                          :class="{'btn-disabled': itemsAtCurrentOrder.length === 0 }"
                          variant="link"
                          class="w-100"
                          @click="printCheck()"
                        >
                          <svg class="icon icon-icon-print-1">
                            <use xlink:href="#icon-icon-print-1" />
                          </svg>

                          <span>
                            {{ $t("amountandpaymentmethod.print-check") }}
                          </span>
                        </b-button>
                      </b-col>
                      <b-col
                        cols="4"
                        class="h-100"
                      >
                        <div
                          v-if="isKitchenPrintProcessing"
                          class="text-center spiner-overbutton"
                        >
                          <b-spinner
                            variant="primary"
                            label="Text Centered"
                          />
                        </div>
                        <b-button
                          :class="{'btn-disabled': !kitchenUpdatesAvailable || currentOrderSum < 0.01}"
                          variant="link"
                          class="w-100"
                          @click="printKitchenCheck()"
                        >
                          <svg class="icon icon-icon-print-2">
                            <use xlink:href="#icon-icon-print-2" />
                          </svg>

                          <span>
                            {{ $t("amountandpaymentmethod.kitchen-print") }}
                          </span>
                        </b-button>
                      </b-col>
                      <b-col
                        cols="4"
                        class="h-100"
                      >
                        <b-button
                          variant="link"
                          class="w-100 btn-disabled"
                        >
                          <svg class="icon icon-icon-print-5">
                            <use xlink:href="#icon-icon-print-5" />
                          </svg>
                          <span>
                            {{ $t("amountandpaymentmethod.open-drawer") }}
                          </span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </div>

                <div class="checkout-bottom px-1 px-lg-4">
                  <div class="d-lg-flex align-items-center justify-content-between h-100">
                    <div class="orders-user">
                      <span class="icon">
                        <img
                          src="../assets/images/customer-photo.png"
                          alt=""
                        >
                      </span>

                      <span>
                        Sauro
                      </span>
                    </div>

                    <div class="checkout-bottom-settings ml-auto">
                      <div class="d-flex align-items-center">
                        <a
                          href="#"
                          class="btn btn-warning"
                        >
                          {{ $t("amountandpaymentmethod.on-account") }}
                        </a>

                        <a
                          href="#"
                          class="btn btn-warning"
                        >
                          {{ $t("amountandpaymentmethod.card-on-fire") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Modal Qty Order -->
                <!-- todo: move to component    -->
                <b-modal
                  id="modal-quantity"
                  :modal-class="terminal && terminal.scale?'popupQuantity':'popup-price'"
                  body-bg-variant="light"
                  no-close-on-backdrop
                  hide-footer
                  no-fade
                >
                  <template
                    slot="modal-header"
                    slot-scope="{ close }"
                  >
                    <b-button
                      variant="outline-light"
                      @click="close()"
                    >
                      {{ $t("quantitypopup.cancel") }}
                    </b-button>
                    <h5>
                      {{ $t("quantitypopup.quantity") }}
                    </h5>
                    <b-button
                      v-if="terminal && terminal.scale"
                      variant="primary"
                      @click="getWeight()"
                    >
                      Get Weight
                    </b-button>
                  </template>

                  <div class="pin-win">
                    <span>{{ quantity.val }} </span>
                  </div>

                  <!-- todo: move to component    -->
                  <div class="pin-keyboard text-center my-0">
                    <ul class="pin-keyboard-list">
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(1)"
                        >1</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(2)"
                        >2</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(3)"
                        >3</span>
                      </li>

                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(4)"
                        >4</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(5)"
                        >5</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(6)"
                        >6</span>
                      </li>

                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(7)"
                        >7</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(8)"
                        >8</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(9)"
                        >9</span>
                      </li>

                      <li>
                        <span
                          class="pin-key pin-key_call"
                          @click="typeQuantityNum('C')"
                        >C</span>
                      </li>
                      <li>
                        <span
                          class="pin-key"
                          @click="typeQuantityNum(0)"
                        >0</span>
                      </li>
                      <li>
                        <span
                          class="pin-key bg-primary"
                          @click="saveNewQuantity()"
                        >
                          <svg class="icon icon-icon-check">
                            <use xlink:href="#icon-icon-check" />
                          </svg>
                        </span>
                      </li>
                      <li v-if="!isKioskMode">
                        <span
                          class="pin-key"
                          style="opacity: 0"
                        />
                      </li>
                      <li v-if="!isKioskMode">
                        <span
                          class="pin-key"
                          @click="typeQuantityNum('.')"
                        >.</span>
                      </li>
                      <li v-if="!isKioskMode">
                        <span
                          class="pin-key"
                          style="opacity: 0"
                        />
                      </li>
                    </ul>
                  </div>
                </b-modal>
                <b-modal
                  id="modal-salecompleted"
                  class="d-print-none"
                  hide-footer
                  hide-header
                  no-close-on-backdrop
                  modal-class="success-popup"
                  no-fade
                >
                  <div class="text-center modal-complete d-print-none">
                    <svg class="icon icon-icon-success-frame">
                      <use xlink:href="#icon-icon-success-frame" />
                    </svg>

                    <h2>
                      {{ $t("salecompletepopup.sale-completed") }}
                    </h2>

                    <span v-show="behavior === 1">
                      {{ $t("salecompletepopup.change-tender") }}
                    </span>
                    
                    <span v-if="this.lastPaymentType && this.lastPaymentType.behavior === 4">
                      Remaining gift card balance
                    </span>
                    
                    <strong
                      v-if="activeGiftCard && lastPaymentType && lastPaymentType.behavior === 4"
                    >
                      {{ currency }} {{ activeGiftCard.credit.toFixed(2) }}
                    </strong>

                    <strong
                      v-show="behavior === 1"
                      class="text-danger"
                    >
                      {{ currency }} {{ changeAmountLast.toFixed(2) }}
                    </strong>

                    <div class="d-flex justify-content-center align-items-center">
                      <b-button
                        variant="primary"
                        @click="newSale()"
                      >
                        {{ $t("customtippopup.newsale") }}
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        class="ml-2"
                        @click="printCheck()"
                      >
                        Print Receipt
                      </b-button>
                    </div>
                  </div>
                </b-modal>
                <b-modal
                  id="modal-kiosksalecompleted"
                  class="d-print-none"
                  hide-footer
                  hide-header
                  no-close-on-backdrop
                  modal-class="success-popup modal-kiosk"
                  no-fade
                >
                  <div class="text-center modal-complete d-print-none">
                    <svg class="icon icon-icon-success-frame-w">
                      <use xlink:href="#icon-icon-success-frame-w" />
                    </svg>

                    <h2 class="text-success">
                      {{ $t("salecompletepopup.sale-completed") }}
                    </h2>

                    <h2
                      v-show="behavior === 1 || isKioskMode"
                      class="text-dark mb-4"
                    >
                      Order # {{ currentOrder["id"] }}
                    </h2>
                    <p class="mb-4">
                      <span v-if="currentOrder.payments.length===0">
                        Please take your receipt and go to the nearest register to pay for your order.
                      </span>
                      <span v-if="currentOrder.payments.length>0">
                        Your order is now being prepared.
                      </span>
                    </p>

                    <div class="d-flex justify-content-center align-items-center">
                      <b-button
                        variant="outline-primary"
                        class="w-100"
                        @click="() => $router.push('/')"
                      >
                        Close
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>

              <div
                class="calc-control d-none d-lg-flex"
                v-if="!isKioskMode"
              >
                <b-button
                  v-for="(paymentType, index) in paymentTypes"
                  :key="index"
                  :class="[(amountTendered < 0.01 && !refundOrderId) || isPaymentProcessing? 'disabled' : '', paymentType.name.toString().toLowerCase().split(' ').join('')]"
                  variant="dark"
                  @click="!isPaymentProcessing?pay(paymentType):null"
                >
                  {{ paymentType.name }}
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      class="d-none d-print-block"
      style="z-index: 100000; width: 100%; position: absolute"
    >
      <PdfReceiptView :order="currentOrder" />
    </div>
    <ModalError
      :id="'modal-error-checkout'"
      :error="error"
      @retry-payment="retryPayment"
    />
    <ProcessingModal modal-title="Waiting for Card Reader..." />
    <SelectCustomerModal
      :order-customer="currentOrder.customer"
      @select-customer="selectCustomer"
      @remove-customer="removeCustomerFromOrder"
    />
    <EnterPinModal @verify-action="verifyAction" />
    <scale-loader
      :loading="isLoad"
      :color="'#007bff'"
    />
    <leaveCurrentOrderModal
      @cancel-order="cancelOrder()"
      @hold-order="holdOrder"
    />
    <SurchargeModal
      :surcharge-value="surchargeValue"
      :order-grand-total="currentOrder.grand_total"
      @surcharge="addSurcharge"
      @cancelSurcharge="cancelSurcharge"
    />
    <GiftCardModal
      :gift-card-checkout="true"
      :checkout-amount="amountTendered"
      @payFromGiftCard="payFromGiftCard"
    />
    <GiftVoucherUnAvailable />
  </pos-layout>
</template>

<script>
import router from "../router";
import { ORDER_STATUS } from "../helpers/order/status";
import { PAYMENT_BEHAVIOR, PAYMENT_REVERSAL_TRANSACTION_TYPE } from "../helpers/order/payment/behavior";
import orderMixin from "../mixins/order/orderMixin";
import shiftMixin from "../mixins/shiftMixin";
import printMixin from "../mixins/printMixin";
import { openDrawer } from "../helpers/printHelper";
import EnterPinModal from "../modals/EnterPinModal";
import PosLayout from "../layouts/PosLayout";
import SimpleNumKeyboard from "../components/SimpleNumKeyboard";
import ModalError from "../modals/ModalError";
import ProcessingModal from "../modals/ProcessingModal";
import SelectCustomerModal from "../modals/SelectCustomerModal";
import orderModifierMixin from "../mixins/order/modifier";
import { TAX_OPTION } from "@/helpers/store/tax_options";
import PdfReceiptView from "../components/PdfReceiptView";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import syncMixin from "../mixins/syncMixin";
import { TRANSACTION_TYPE } from "@/helpers/order/transaction/types";
import { mapActions, mapGetters } from "vuex";
import cdOrderMixin from "../mixins/cdOrderMixin";
import LeaveCurrentOrderModal from "../modals/Order/LeaveCurrentOrderModal.vue";
import SurchargeModal from "../modals/SurchargeModal.vue";
import * as Sentry from "@sentry/vue";
import GiftCardModal from "../modals/Order/MoreOptions/GiftCardModal.vue";
import GiftVoucherUnAvailable from "../modals/GiftVoucherUnAvailable.vue"
import customerDisplayMixin from "@/mixins/customerDisplayMixin";
import { toFixedNoRounding } from "@/helpers/utils";
import { makeDejavooPayment, makeDejavooRefund } from "../helpers/payments/Dejavoo";

export default {
	name: "OrderCheckout",
	components: {
		PosLayout,
		SimpleNumKeyboard,
		EnterPinModal,
		ModalError,
		ProcessingModal,
		SelectCustomerModal,
		PdfReceiptView,
    ScaleLoader,
    LeaveCurrentOrderModal,
    SurchargeModal,
    GiftCardModal,
    GiftVoucherUnAvailable
	},
	mixins: [orderMixin, printMixin, orderModifierMixin, shiftMixin, syncMixin, cdOrderMixin, customerDisplayMixin],
	data () {
		return {
			error: {
				title: "Unknown error...",
				info: "Please, contact support"
			},
			lastPaymentType: null,
			sidebarMob: true,
			baseURL: localStorage.baseURL,
			itemIdCounter: 0,
			currentEmployee: null,
			quantity: {
				val: "",
				changed: false
			},
			currentOrder: {
				localID: 0,
				subtotal: 0,
				items: [],
				table: undefined,
				discount_total: 0,
        order_discount: 0,
				tax_total: 0,
				payments: [],
				customer: null,
				cash_discount: false,
        allow_surcharge:false,
        order_gift_card_payment:[]
			},
			amountTenderedString: "0",
			table: null,
			isDisplayPaymentDetails: false,
			behavior: PAYMENT_BEHAVIOR.ALLOW_CHANGE,
			longPress: {
				interval: false,
				count: 0
			},
			isPaymentProcessing: false,
			isVerified: true,
			isKitchenPrintProcessing: false,
      isLoad: false,
      refundTenderedAmount:false,
      surchargeValue:0,
      isGiftCardPayment:false
		};
	},
  computed: {
    ...mapGetters({
      orders:"orders/getAllOrders",
      refundOrderId: 'orders/refundOrderId',
      tables: "config/getAllTables",
      isSurcharged:"surcharge/isSurcharged",
      updatingGiftCardPayments:"orders/updatingGiftCardPayments",
      activeGiftCard:'giftVoucher/activeGiftCard',
      isGiftCardPaymentInitiated:'giftVoucher/isGiftCardPaymentInitiated'
    }),
		terminal () { return this.$store.getters["config/terminal"];},
		products () { return this.$store.getters["config/products"];},
		employee () { return this.$store.getters["employee"];},
		store () { return this.$store.getters["config/store"];},
		isKioskMode () {
			return this.$store.getters["config/isKioskMode"];
		},
    changeAmountLast() {
      if (this.currentOrderSumAmount < 0.0 && this.currentOrderSum < 0.0) {
        return parseFloat(this.currentOrderSum.toFixed(2))
      }
      const difference = this.currentOrderSumAmount - this.currentOrderSum;
      return parseFloat(difference.toFixed(2))
		},
		currentOrderSumAmount () {
			let sumAmount = 0;
			if (this.currentOrder.payments) {
				this.currentOrder.payments.map((payment) => {
					sumAmount += payment.amount;
				});
			}
			return parseFloat(sumAmount.toFixed(2));
		},
    terminalMode () {
			return this.$store.getters.terminalMode;
		},
		currentOrderSum () {
			return this.currentOrder.grand_total;

		},
		currentOrderGrandTotalSum () {
      const difference = this.currentOrderSum - this.currentOrderSumAmount;
      return parseFloat(difference.toFixed(2))
		},
    amountTendered() {
      return parseFloat(this.amountTenderedString ? this.amountTenderedString : "0") / 100;

		},
		amountChange: function () {
      const difference = parseFloat((this.amountTendered - this.currentOrderGrandTotalSum).toFixed(2));
      if(this.currentOrderSum > 0.0)
        return difference > 0.0 ? difference : 0;
      else
      return difference
		},
		quickCash: function () {
      if (this.currentOrderSum < 0.00)
        return []
			const choices = [];
			let coefficient = 0;
			const multiplier = 10;

			do {
				if (!coefficient) {
					choices.push(Math.ceil(this.currentOrderGrandTotalSum));
					coefficient = multiplier;
					continue;
				}
				let result = Math.ceil(this.currentOrderGrandTotalSum / coefficient) * coefficient;
				if (choices.find(choice => choice === result)) {
					result = Math.max(...choices) + this.currentOrderGrandTotalSum;
				}
				choices.push(Math.round(result));
				coefficient *= multiplier;
			} while (choices.length < 3);

			return choices;
		},
		currency () {
			return this.$store.getters["config/currency"];
		},
    // giftCardPaymentAmount(){
    //   const giftCardAmountArray =  this.currentOrder.order_gift_card_payment.map(payment=>payment.amount_paid)
    //   return parseFloat(giftCardAmountArray.reduce((acc,amount)=> acc+amount))
    // }
	},
	watch: {
		paymentTypes () {
			if (!this.isPaymentTypesAvailable) router.go(-1);
    },
    "currentOrder.isActive": {
      handler(isActive) {
        try {
           // setting active state in order to control the showing of leaveCurrentModal on the isActive attribute
            if (isActive)
              this.setActiveOrderState(true)
            else {
              this.setActiveOrderState(false)
            }
        }
        catch (err) {
          //pass
        }
      },
      immediate: true

    },
    updatingGiftCardPayments:{
      handler(payments){
        if(payments.length>0)
        this.currentOrder.order_gift_card_payment = [...payments]
          // this.currentOrder.order_gift_card_payment.forEach(payment=>{
          //   let foundPayment = null
          //   foundPayment = payments.find(updatedPayment=>updatedPayment.local_id === payment.local_id)
          //   if(foundPayment){
          //     payment = foundPayment
          //   }
          // })
      },
      immediate:true
    },
},
  async created() {
      if (this.orders.length === 0)
        await this.fetchOrdersFromDb()
      if(this.products.length === 0)
        await this.fetchProductsFromDb()
    if (this.tables.length === 0)
      await this.fetchTablesFromDb()
    const orderKey = this.$route.params.id.toString().includes("_") ? this.$route.params.id
      : parseInt(this.$route.params.id);
    this.currentOrder = {...this.$store.getters["orders/getOrderByIdOrLocalId"](orderKey)};
    const currentOrderItems = this.currentOrder.items
    this.currentOrder.items = currentOrderItems.map(item => {
      const productConfig = this.products.find(product => product.id === item.product)
      if (productConfig.printers.length > 0) {
        item.printers = productConfig.printers
        item.has_kitchen_updates = !!(productConfig.printers.length)
      }
      return item

    })
    // for kisok mode the amount tendered will be shown by default
    if(this.isKioskMode){
        this.amountTenderedString = (this.currentOrderGrandTotalSum * 100).toString();
      }

      if(this.currentOrder.status === ORDER_STATUS.COMPLETED || this.currentOrder.status === ORDER_STATUS.REFUNDED){
        if(this.isKioskMode){
          this.$bvModal.show("modal-kiosksalecompleted")
        }
        else{
          this.$bvModal.show("modal-salecompleted")
        }
      }
  },
	beforeMount: async function () {
		this.is_tax_inclusive = this.store.tax_option == TAX_OPTION.TAX_INCLUSIVE;
	},
  mounted(){
    if(this.currentOrder.status === ORDER_STATUS.COMPLETED || this.currentOrder.status === ORDER_STATUS.REFUNDED){
      if(this.isKioskMode){
          this.$bvModal.show("modal-kiosksalecompleted")
        }
        else{
          this.$bvModal.show("modal-salecompleted")
        }
      }
  },
  async beforeDestroy() {

    // reset all the value related to surcharge on destroy of component
    this.currentOrder.surcharge_value = 0
    this.setSurcharge(false)

    if (this.currentOrder.status === ORDER_STATUS.OPEN || this.currentOrder.status === ORDER_STATUS.HOLD) {
      this.currentOrder.locked_by = null;
      await this.$store.commit("orders/saveOrder", this.currentOrder);
      await this.$store.dispatch("sync");
    }
    if (this.$route.name === "Tables") {
      this.$store.dispatch('toggleCallOrderList', true)
    }
	},
  methods: {
    ...mapActions('orders', ['setRefundOrderId','fetchOrdersFromDb']),
    ...mapActions('config',['fetchProductsFromDb','fetchTablesFromDb']),
    ...mapActions('surcharge',['setSurcharge']),
    ...mapActions('giftVoucher',['fetchGiftCardPayments', 'updateGiftCardCredits', 'recordGiftCardPayments','toggleGiftCardPaymentInitiater']),
    toFixedNoRounding,
		async goToTableOrders () {
			await this.$router.push({ name: "TableOrders", params: { id: this.currentOrder.table } });
		},
		async verifyAction (employee) {

			if (!employee) {
				return this.isVerified = false;
			}
			this.isVerified = true;
			await this.cancelOrderProcessing();

			this.isVerified = false;

			return this.$bvModal.hide("enter-pin-modal");
		},
		showSelectCustomerModal () {
			return this.$bvModal.show("select-customer-modal");
		},
		async selectCustomer (customer) {
			return this.assignCustomerToOrder(customer);
		},
		receiveMessage (event) {
			const self = this;
			if (event.data.action !== "sync") {
				self.updateLocalId(event.data);
			}
		},
		start () {
			if (!this.interval) {
				this.longPress.interval = setInterval(() => {
					this.longPress.count++;
					if (this.longPress.count === 50) {
						this.printCheck();
					}
				}, 30);
			}
		},
		stop () {
			clearInterval(this.longPress.interval);
			this.longPress.interval = false;
			this.longPress.count = 0;
		},
		sidebarMobToggle () {
			this.sidebarMob = !this.sidebarMob;
		},
		typeNum (num) {
			num = num === "" ? 0 : num;
			this.amountTenderedString = num + "";
		},
		typeQuantityNum: function (num, quantityVal = null) {
			if (num === "C") {
				this.quantity.val = "";
			} else if (typeof num !== "undefined" && !quantityVal) {
				if (this.quantity.changed) {
					this.quantity.val += num.toString();
				} else {
					this.quantity.val = num.toString();
				}
				this.quantity.changed = true;
			} else if (quantityVal) {
				this.quantity.val = quantityVal;
			}
		},
		resetNum () {
			this.amountTenderedString = "0";
		},
		typeCustom (amount) {
      this.amountTenderedString = (parseFloat(amount) * 100) + "";
		},
		sum: function (items, prop) {
			return items.reduce(function (a, b) {
				return a + b[prop] * b["quantity"];
			}, 0);
		},
		async updateLocalId (msgObj) {
			if (!this.currentOrder || !this.currentOrder.id) return false;
			if (this.currentOrder.id === msgObj.localID) {
				this.currentOrder.id = msgObj.remoteID;
			}
		},
		async timeout (ms, promise, controller) {
			return new Promise(function (resolve, reject) {
				setTimeout(function () {
					reject(new Error("timeout"));
					controller.abort();
				}, ms);
				promise.then(resolve, reject);
			});
		},
		
        async sendNABancardRequest (paymentType, amount, surchargeValue=0) {
			if (paymentType.behavior !== PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION) {
				await this.$bvModal.hide("processing-modal");
				return null;
			}
			const url = paymentType.url;
			if (!url) return false;
			const headers = new Headers({
                "Cache-Control": "no-cache",
				"Content-type": "application/xml",
				"Host": url.replace("http://", "").replace("https://", "")
			});

      let body = null
      if(this.refundTenderedAmount){
        body = `<DETAIL><TRAN_TYPE>${PAYMENT_REVERSAL_TRANSACTION_TYPE[paymentType.transaction_type]}</TRAN_TYPE><AMOUNT>${(-1*parseFloat(amount)).toFixed(2)}</AMOUNT></DETAIL>`;
      }
      else{
			body = `<DETAIL><TRAN_TYPE>${paymentType.transaction_type}</TRAN_TYPE><AMOUNT>${(parseFloat(amount)+parseFloat(surchargeValue)).toFixed(2)}</AMOUNT></DETAIL>`;
      }
			const self = this;
			let paymentResponse;
			const controller = new AbortController();
			const signal = controller.signal;
			await this.timeout(180000, fetch(url, {
				method: "post",
				signal: signal,
				mode: "cors",
				headers,
				body
			}), controller).then(response => response.text())
				.then(data => {
					self.$bvModal.hide("processing-modal");

					paymentResponse = data;
				}).catch(function (error) {
					console.error(error);
					self.$bvModal.hide("processing-modal");
					self.showModalError("Payment Error", "Unsuccessful");

					paymentResponse = "";
				});
			return paymentResponse;
		},
		async retryPayment() {
			// Show processing modal before closing error modal to prevent flicker
			await this.$bvModal.show("processing-modal");
			this.$bvModal.hide("modal-error-checkout");

			return this.pay(this.lastPaymentType);
		},
		async NABancardPaymentProcessing (paymentType, amount) {
			const defaultEmptyResponse = {
				response: "",
				NABancardResponseObject: {}
			};
			let response = "";
			const NABancardResponseObject = {};
			try {
				await this.$bvModal.show("processing-modal");
        const surchargeAmount = this.currentOrder.surcharge_value ? this.currentOrder.surcharge_value : 0
				response = await this.sendNABancardRequest(paymentType, amount, surchargeAmount);
				this.$bvModal.hide("processing-modal");
				if (!response) {
					this.showModalError("Payment Error", "Unsuccessful");
					return defaultEmptyResponse;
				}
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(response, "application/xml");
				NABancardResponseObject.response_text = xmlDoc.getElementsByTagName("AUTH_RESP_TEXT")[0].childNodes[0].nodeValue;
				NABancardResponseObject.response_code = xmlDoc.getElementsByTagName("AUTH_RESP")[0].childNodes[0].nodeValue;
				let tips = 0;
				try {
					tips = xmlDoc.getElementsByTagName("TIP_AMT")[0].childNodes[0].nodeValue;
					NABancardResponseObject.tips = tips ? parseFloat(tips) : 0;
				} catch (e) {
          Sentry.captureException(e);
					console.warn("Could not parse tips");
				}
				if (NABancardResponseObject.response_code !== "00") {
					console.warn("No approval found in response_text", NABancardResponseObject.response_text);
					this.showModalError("Payment Error", "Unsuccessful Response:\"" + NABancardResponseObject.response_text + "\"");
					return { ...defaultEmptyResponse, ...{ error: NABancardResponseObject.response_text } };
				}

				return {
					response,
					NABancardResponseObject,
					error: "Unknown error"
				};
			} catch (e) {
        Sentry.captureException(e);
				console.error("e", e);
				return defaultEmptyResponse;
			}
		},
		async DejavooPaymentProcessing (paymentType, amount) {
			try {
				const subdomain = window.location.host.split('.')[0];
				const orderId = this.currentOrder.id || this.currentOrder.local_id;
				const timestamp = Math.floor(Date.now());
				const referenceId = `${subdomain}_${orderId}_${timestamp}`;
				const invoiceNumber = orderId;
				
        await this.$bvModal.show("processing-modal");
        
        let response;
        if (amount < 0) {
          // Handle refund
          response = await makeDejavooRefund(
            paymentType.url,
            amount,
            referenceId,
            invoiceNumber,
            paymentType.transaction_type
          );
        } else {
          // Handle sale
          response = await makeDejavooPayment(
            paymentType.url,
            amount,
            referenceId,
            invoiceNumber,
            paymentType.transaction_type
          );
        }

        this.$bvModal.hide("processing-modal");

				// Response error check is now handled in makeDejavooPayment/makeDejavooRefund
				// We'll only get here if the payment/refund was approved
				return response;
			} catch (error) {
				console.error('Error in Dejavoo payment processing:', error);
        this.$bvModal.hide("processing-modal");
        this.showModalError("Payment Error", error.message || "Payment processing failed");
				throw error;
			}
		},
		pay: async function (paymentType) {
			const orderTotalSumBeforePayment = this.currentOrderGrandTotalSum;
      const hasConnectivity = await this.checkInternetConnectivity()
      if(paymentType.behavior === PAYMENT_BEHAVIOR.GIFT_CARD && !hasConnectivity){
        this.$bvModal.show('gift-card-not-available')
        return
      }
			if (this.isPaymentProcessing) {
				return true;
			}
			if (this.isKioskMode && !paymentType) {
				this.currentOrder = {
					...this.currentOrder,
					status: ORDER_STATUS.HOLD,
					hold_date: new Date().toISOString(),
					description: "Pay at the register"
				};
				this.isLoad = false;
				this.$bvModal.show("modal-kiosksalecompleted");
        // automatically close the kiosk sales completed modal after 10 sec
        this.closeKioskSaleCompletedModal()
				if (this.terminal.printer) {
					await this.printCheck();
				}
			}
      this.lastPaymentType = paymentType;
      // if the sum is 0.00 then it should check out directly
      if(this.currentOrderSum !== 0.00)
      // if(this.amountTendered < 0.01 && paymentType.name === 'Gift Card' && this.giftCardPaymentAmount > 0){
      //   return this.amountTenderedString = (parseFloat(this.giftCardPaymentAmount)*100).toString();

      // }
      // // checking for gift card transactions
      // else if(this.amountTendered > 0 && paymentType.name === 'Gift Card' && this.currentOrder.order_gift_card_payment.length>0){
      //   if(this.amountTendered > this.giftCardPaymentAmount){
      //     return
      //   }
      //   else if(this.currentOrder.order_gift_card_payment.every(payment => payment.transaction_type === 1)){
      //     return
      //   }
      // }
      if ((this.amountTendered < 0.01 && !this.refundOrderId) || (this.amountTendered < 0.01 && !this.refundTenderedAmount) ) {
        this.refundTenderedAmount = this.refundOrderId ? true : false
        // return this.amountTenderedString = (this.currentOrderGrandTotalSum * 100).toString();
        if(paymentType.behavior === PAYMENT_BEHAVIOR.GIFT_CARD){
          this.amountTenderedString = (this.currentOrderGrandTotalSum * 100).toString();
          this.$bvModal.show('gift-voucher-modal')
          this.toggleGiftCardPaymentInitiater()
          return this.amountTenderedString
        }
        else{
          return this.amountTenderedString = (this.currentOrderGrandTotalSum * 100).toString();
        }
      }

      if(!this.isGiftCardPaymentInitiated && paymentType.behavior === PAYMENT_BEHAVIOR.GIFT_CARD){
        this.$bvModal.show('gift-voucher-modal')
        this.toggleGiftCardPaymentInitiater()
        return
      }

      // show surcharge modal for paymenttype with surcharge value more than 0 and the order is not refunded and the it has not been surcharge previously
      if(paymentType.surcharge && paymentType?.surcharge > 0 && !this.isSurcharged && !this.refundTenderedAmount){
        this.showSurchargeModal(paymentType.surcharge)
        return
      }

			this.isPaymentProcessing = true;

			this.behavior = paymentType.behavior;
			let response = "";
			let NABancardResponseObject = {};
			let DejavooResponseObject = {};
			let amount = Number(this.amountTendered.toFixed(2));
			if (
				(this.behavior === PAYMENT_BEHAVIOR.DIRECT_CHARGE
					|| this.behavior === PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION)
				&& this.amountTendered >= this.currentOrderGrandTotalSum
			) {
				amount = Number(this.currentOrderGrandTotalSum.toFixed(2));
			}
			let tips = this.currentOrder.tips_total || 0;

      const isDejavooEnabled = this.terminal.db_settings.find((item) => item.name === "dejavoo_test")
      && this.terminal.db_settings.find((item) => item.name === "dejavoo_test")["value"] === "true";

			// todo move to separate method
			if (this.behavior === PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION) {
				const NABancardResponse = await this.NABancardPaymentProcessing(paymentType, amount);
				response = NABancardResponse.response;
				if (response) {
					tips = NABancardResponse.NABancardResponseObject && NABancardResponse.NABancardResponseObject.tips ? NABancardResponse.NABancardResponseObject.tips : tips;
				}
				NABancardResponseObject = NABancardResponse.NABancardResponseObject;
				if (!response){
          this.cancelSurcharge()
          return this.showModalError("Payment Error", `Unsuccessful. Reason: ${NABancardResponse && NABancardResponse.error ? NABancardResponse.error : "No info"}`);
        }
			} else if (isDejavooEnabled && this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION) {
				const DejavooResponse = await this.DejavooPaymentProcessing(paymentType, amount);
				response = JSON.stringify(DejavooResponse);
				DejavooResponseObject = DejavooResponse;

				// Extract crucial credit card details from Dejavoo response
				if (DejavooResponse && DejavooResponse.CardData) {
					DejavooResponseObject.card_lastfour = DejavooResponse.CardData.Last4 || '';
					DejavooResponseObject.card_type = DejavooResponse.CardData.CardType || '';
					DejavooResponseObject.entry_type = DejavooResponse.CardData.EntryType || '';
					DejavooResponseObject.card_first4 = DejavooResponse.CardData.First4 || '';
					DejavooResponseObject.card_bin = DejavooResponse.CardData.BIN || '';
					DejavooResponseObject.card_expiration = DejavooResponse.CardData.ExpirationDate || '';
					DejavooResponseObject.cardholder_name = DejavooResponse.CardData.Name || '';
				}

				if (DejavooResponse) {
					DejavooResponseObject.auth_code = DejavooResponse.AuthCode || '';
					DejavooResponseObject.transaction_type = DejavooResponse.TransactionType || '';
					DejavooResponseObject.reference_id = DejavooResponse.ReferenceId || '';
					DejavooResponseObject.approval_message = DejavooResponse.GeneralResponse ? 
						(DejavooResponse.GeneralResponse.DetailedMessage || DejavooResponse.GeneralResponse.Message || '') : '';
				}
			}
			const currentShift = this.$store.getters.shift;
      const balance = parseFloat((this.currentOrderGrandTotalSum - this.amountTendered).toFixed(2));
			this.currentOrder.balance_remaining = (balance < 0 && !this.refundOrderId) ? 0 : parseFloat(balance.toFixed(2));
			const changeAmount = this.behavior === PAYMENT_BEHAVIOR.ALLOW_CHANGE ? this.amountChange : 0;

			const paymentObject = {
				order: this.currentOrder.id ? this.currentOrder.id : this.currentOrder.local_id,
				employee: this.employee ? this.employee.id : null,
				amount,
				amount_paid: amount > this.currentOrderGrandTotalSum ? this.currentOrderGrandTotalSum : amount,
				tips: tips,
				change_amount: changeAmount,
				payment_type: paymentType ? paymentType.name : null,
				payment_date: new Date().toISOString(),
				related_terminal: this.terminal.id,
				transaction_type: this.currentOrderSum < 0.01 ? TRANSACTION_TYPE.RETURN : TRANSACTION_TYPE.SALE ,
				processor_response: (this.behavior === PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION || this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION)
					? response
					: "no data",
				card_lastfour: this.behavior === PAYMENT_BEHAVIOR.NABANCARD_INTEGRATION
					? NABancardResponseObject.card_lastfour
					: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.card_lastfour
					: null,
				card_type: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.card_type
					: null,
				entry_type: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.entry_type
					: null,
				auth_code: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.auth_code
					: null,
				dejavoo_transaction_type: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.transaction_type
					: null,
				approval_message: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.approval_message
					: null,
				reference_id: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.reference_id
					: null,
				cardholder_name: this.behavior === PAYMENT_BEHAVIOR.DEJAVOO_INTEGRATION
					? DejavooResponseObject.cardholder_name
					: null,
				local_id: `${this.terminal.id}_${this.currentOrder.payments.length + 1}_${Date.now()}`,
				shift: currentShift.id,
        gift_card: this.activeGiftCard ? this.activeGiftCard.id : null
			};
			if (this.currentOrder.payments) {
				this.currentOrder.payments.push(paymentObject);
			} else {
				this.currentOrder.payments = [paymentObject];
			}
			if (!this.isKioskMode) {
				await this.savePaymentToShift({ name: paymentType.name, amount: paymentObject.amount_paid });
      }
      if (this.amountTendered >= orderTotalSumBeforePayment) {
      if (typeof this.refundOrderId === 'string') {

      if (this.refundOrderId.includes("_"))

     this.currentOrder.reference_order = null

      } else {

      this.currentOrder.reference_order = this.refundOrderId

      }
				this.currentOrder.status = this.currentOrderSum < 0.00 ? ORDER_STATUS.REFUNDED : ORDER_STATUS.COMPLETED
				this.currentOrder.close_date = new Date().toISOString();
				if (!this.isKioskMode) {
					this.currentOrder.related_terminal = this.terminal.id;
					this.currentOrder.shift = currentShift.id;
					this.currentOrder.emp_close = this.employee ? this.employee.id : null;
				}
			}
      this.isLoad = true;

      this.$store.commit("orders/saveOrder", this.currentOrder);
      this.$store.dispatch("sync");
      if(paymentType.behavior === PAYMENT_BEHAVIOR.GIFT_CARD)
        this.toggleGiftCardPaymentInitiater()
      this.sendOrderToChannel(this.currentOrder)
			this.isLoad = false;
			if (this.amountTendered >= orderTotalSumBeforePayment && !this.isKioskMode) {
				const dbSettings = JSON.parse(JSON.stringify(this.terminal["db_settings"]));
				const autoSendKitchen = dbSettings ? await dbSettings.find(setting => setting.name === "autosend_kitchen") : false;
				if (autoSendKitchen && autoSendKitchen["value"] === "true" && this.currentOrderSum > 0.00) {
					await this.printKitchenCheck();
        }
        const newSale = {
          isSale: true,
          sale: {
            isCash: this.behavior === PAYMENT_BEHAVIOR.ALLOW_CHANGE ? true : false,
            transactionAmount: this.amountTendered + parseFloat(this.currentOrder.surcharge_value),
            changeTenderAmount: this.changeAmountLast
          }

        }
        this.sendOrderToChannel(newSale)
        this.setSurcharge(false)
        this.setRefundGiftCardIds([])
        if (this.$store.getters["giftVoucher/selectedGiftCards"].length > 0) {
          await this.creditSelectedGiftCards()
        }
				this.$bvModal.show("modal-salecompleted");
			} else if (this.amountTendered >= orderTotalSumBeforePayment && this.isKioskMode) {
        if (this.$store.getters["giftVoucher/selectedGiftCards"].length > 0) {
          await this.creditSelectedGiftCards()
        }
				this.$bvModal.show("modal-kiosksalecompleted");
        // automatically close the kiosk sales completed modal after 10 sec
        this.closeKioskSaleCompletedModal()
				if (this.terminal.printer) {
          await this.printCheck();
          if (this.currentOrderSum > 0.00) {
            await this.printKitchenCheck();
          }
				}

			}
			setTimeout(() => {
				if (parseFloat(parseFloat(this.currentOrderGrandTotalSum).toFixed(2)) <= 0) {
					try {
						if (this.behavior === PAYMENT_BEHAVIOR.ALLOW_CHANGE) {
							openDrawer();
						}
					} catch (e) {
            Sentry.captureException(e);
						console.error("[OrderCheckout] OpenDrawer error", e);
					}
				} else {
					console.warn("this.currentOrderGrandTotalSum>0 -> ", this.currentOrderGrandTotalSum);
				}
				this.isPaymentProcessing = false;
			}, 500);
			this.resetNum();
		},
    newSale: async function () {
      const savedOrder = this.$store.getters["orders/getOrderByIdOrLocalId"]((this.currentOrder.id || this.currentOrder.local_id));
			if (!savedOrder || savedOrder.status !== 3) {
				console.warn("Prev order is not closed..");
        if (!this.refundOrderId)
          savedOrder.status = 3;
        else {
          this.setRefundOrderId(null)
          this.refundTenderedAmount = false
        }
      }

			if (!this.currentOrder.table && this.terminalMode === "QUICK_SERVICE") {
        // await this.emptyOrder()
        return await this.$router.push({
					name: "Order",
					params: {
						tableID: null,
						id: "new"
					}
				});
			} else if (!this.currentOrder.table) {
				return await router.push({ name: "Tables" });
			}
			this.table = this.$store.getters.getTableWithOrders(this.currentOrder.table);
			if (this.table && this.table.tableOrders && this.table.tableOrders.length === 0) {
				await router.push({ name: "Tables" });
			} else if (this.table && this.table.tableOrders) {
				await router.push({ name: "TableOrders", params: { id: this.currentOrder.table } });
			} else if (this.isKioskMode) {
				await router.push({ name: "KioskHome" });
			}
		},
		showModalError (title, info) {
			this.error.title = title;
			this.error.info = info;
			this.isPaymentProcessing = false;
			this.$bvModal.show("modal-error-checkout");
		},
		displayPaymentDetails: function () {
			return this.isDisplayPaymentDetails = !this.isDisplayPaymentDetails;
    },
    async holdOrder(payload) {
      if (this.$route.params.id === "new" || this.currentOrder.id === 0) return true;
      this.currentOrder = {
        ...this.currentOrder,
        status: ORDER_STATUS.HOLD,
        hold_date: new Date().toISOString(),
        description: payload.description,
        locked_by:null
      };

      // await this.sync();
      if (!this.currentOrder.table) {
        await router.push({
          name: "NewOrderRedirect",
          params: {}
        }, () => {
        });
        return null;
      }

      this.table = this.$store.getters.getTableWithOrders(this.currentOrder.table);
      this.table.tableOrders.length === 0
        ? await router.push({ name: "Tables" })
        : await router.push({ name: "TableOrders", params: { id: this.currentOrder.table } }, () => {
        });
    },
    closeKioskSaleCompletedModal(){
      // automatically close the kiosk sales completed modal after 10 sec
      setTimeout(()=>{
        const self = this
        self.$bvModal.hide("modal-kiosksalecompleted")
        self.$router.push({path:'/'})
      },10000)
    },
    showSurchargeModal(surchargeValue=0){
      this.surchargeValue = surchargeValue
      this.currentOrder.surcharge_value = parseFloat((this.surchargeCharged(this.currentOrder.grand_total,this.surchargeValue)).toFixed(2))
      this.sendOrderToChannel(this.currentOrder)
      this.$bvModal.hide('processing-modal')
      this.$bvModal.show('surcharge-modal')

    },
    addSurcharge(){
      this.setSurcharge(true)
      this.$bvModal.hide('surcharge-modal')
      this.pay(this.lastPaymentType)
    },
    cancelSurcharge(){
      // reset all the value related to surcharge on trsaction failure of NAB cards
        this.currentOrder.surcharge_value = 0
        this.setSurcharge(false)
        this.$bvModal.hide('surcharge-modal')
        this.$bvModal.hide('processing-modal')
        this.sendOrderToChannel(this.currentOrder)
    },
    creditSelectedGiftCards(){
      this.updateGiftCardCredits()
    },
    async payFromGiftCard(giftCardPayment){
      giftCardPayment.order = this.currentOrder.id
      giftCardPayment.local_id = this.currentOrder.local_id
      if(await this.recordGiftCardPayments(giftCardPayment)){
        if(this.amountTendered !== giftCardPayment.amount_paid){
          this.amountTenderedString = (giftCardPayment.amount_paid* 100).toString()
        }
        this.pay(this.lastPaymentType)
      }
    }

	}
};

</script>


<style lang="scss">
.calc-control {
	overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calc-control::-webkit-scrollbar {
	-webkit-appearance: none;
}

.calc-control::-webkit-scrollbar:vertical {
	width: 11px;
}

.calc-control::-webkit-scrollbar:horizontal {
	height: 11px;
}

.calc-control::-webkit-scrollbar-thumb {
	border-radius: 8px;
	border: 2px solid white; /* should match background, can't be transparent */
	background-color: rgba(0, 0, 0, .5);
}

.qash {
	margin-bottom: 30px;

	.btn {
		height: 50px;
		width: 140px;
	}
}

.btn-disabled {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.4;
}

.sent_to_kitchen .align-items-center {
	font-style: italic;
	color: #888888;
}

.spiner-overbutton {
	position: absolute;
	top: 19px;
	z-index: 10;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	text-align: center;
}
</style>
