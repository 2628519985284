import store from "../store/store";
import * as Sentry from "@sentry/vue";

export async function backgroundSync (fetchOrder=true) {
  store.commit("startSync");
  try {
    store.dispatch("customers/uploadUpdated");
    store.dispatch("shifts/uploadUpdated");
    // just before order need update synced related ids
   store.commit("orders/updateLocalIds");
    // await store.dispatch("orders/fetchOrders");
    if(fetchOrder) {
      await store.dispatch("orders/uploadOfflineOrders");
      if (store.getters.terminalMode === "TABLE_SERVICE") {
        store.dispatch("config/fetchSections");
      }
    }

    store.dispatch("config/uploadUpdatedTables");
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
  }
  store.commit("endSync");
}

