import { calculateItemTaxTotal, getInclusiveTax, getItemGrandTotal, getItemSubtotal, getModSum } from "../../../helpers/calc";
import { toFixed } from "../../../helpers/utils";

export default {
  data () {
    return {
      modifiersShow: false,
      modifiers: {
        modifierGroupsAtCurrentItem: [],
        modifierIdsAtCurrentItem: [],
        isNewItem: false
      }
    };
  },
  methods: {
    modifiersOpen (itemId, isNewItem = false) {
      const index = isNewItem ? (this.currentOrder.items.length - 1) : this.currentOrder.items.findIndex(currentItem => currentItem.id === itemId);
      const product = this.products.find(prod => prod.id === this.currentOrder.items[index].product);
      this.modifiersShow = true;
      this.modifiers.product = product;
      this.modifiers.isNewItem = isNewItem;
      this.modifiers.currentItemIndex = index;
      this.modifiers.modifierIdsAtCurrentItem = [];
      for (const mod of this.currentOrder.items[index].modifiers) {
        if (!mod.void_status) {
          this.modifiers.modifierIdsAtCurrentItem.push(mod.oryginal);
        }
      }
      this.modifiers.modifierGroupsAtCurrentItem = this.getProductModifierGroups(
        this.currentOrder.items[index].product);
    },
    async modifiersSave () {
      const hasErrors = [];
      this.modifiers.modifierGroupsAtCurrentItem.forEach(modGroup =>
        hasErrors.push(!this.isValidCountSelectedModifiersInGroup(modGroup))
      );
      if (hasErrors.filter(err => err === true).length) return false;
      const modifiersAtSaveStage = [];
      const index = this.modifiers.currentItemIndex;
      const item = this.currentOrder.items[this.modifiers.currentItemIndex];
      for (const mod of item.modifiers) {
        if (!mod.void_status) {
          const modToSave = mod.oryginal;
          modifiersAtSaveStage.push(modToSave);
        }
      }
      if (!this.currentOrder.items[index].has_kitchen_updates && !(modifiersAtSaveStage.sort().equals(
        JSON.parse(JSON.stringify(this.modifiers.modifierIdsAtCurrentItem.sort()))))
      ) {
        this.markUpdated(this.modifiers.currentItemIndex, "modifier change");
      }
      this.modifiersShow = false;
      this.currentOrder.items[index].subtotal = getItemSubtotal(this.currentOrder.items[index]);
      this.currentOrder.items[index].grand_total = parseFloat((getItemGrandTotal(this.currentOrder.items[index])).toFixed(2));
      this.currentOrder.items[index].tax = this.currentOrder.items[index].tax ? calculateItemTaxTotal(this.currentOrder.items[index]) : 0;
      if (this.modifiers.isNewItem) {
        await this.calculateItemCashDiscountChange(index, true);
      }
      await this.countSubtotal()
      // await this.sync(false, true);
    },
    isValidCountSelectedModifiersInGroup (modGroup) {
      const { min_selection, max_selection } = modGroup;
      const countSelectedModifiersInGroup = this.countSelectedModifiers(modGroup);
      if (!min_selection) return true;
      if (min_selection && (min_selection > countSelectedModifiersInGroup)) {
        this.dispatchModifiersError(
          `You must select at least ${min_selection} modifiers in group ${modGroup.name}`
        );
        return false;
      }

      if (max_selection && (countSelectedModifiersInGroup > max_selection)) {
        this.dispatchModifiersError(
          `You can only select up to ${max_selection} modifiers for maximum in group ${modGroup.name}`
        );
        return false;
      }
      return true;
    },
    removeModFromItem (modifier, modGroup) {
      this.currentOrder.items[this.modifiers.currentItemIndex].modifiers.map(itemModifier => {
        if (modifier.id === itemModifier.oryginal && itemModifier.group === modGroup.id)
          itemModifier.void_status = true;
      });
    },
    isModifierSelected (modifier, modGroup) {
      return this.currentOrder.items[this.modifiers.currentItemIndex].modifiers.find(
        mod => mod.oryginal === modifier.id && !mod.void_status && mod.group === modGroup.id);
    },
    async toggleModifierForItem (modifier, modifierGroup) {
      if (this.isModifierSelected(modifier, modifierGroup)) {
        this.removeModFromItem(modifier, modifierGroup);
      } else {
        if (!this.hasAbilityToAddNewModifier(modifierGroup.max_selection, modifierGroup)) return;
        let modPrice = JSON.parse(JSON.stringify(modifier.price));
        if (this.is_tax_inclusive) {
          let taxSum = 0;
          for (const tax_rate of this.modifiers.product["tax_rates"]) {
            taxSum += parseFloat(toFixed(getInclusiveTax(modifier.price, tax_rate.rate / 100), 2));
          }
          modPrice = parseFloat(toFixed(modifier.price - taxSum, 2));
        }
        const orderModifier = {
          "name": modifier.name,
          "price": modPrice,
          "price_original": modifier.price,
          "cost": modifier.cost,
          "group": modifierGroup.id,
          "oryginal": modifier.id,
          "void_status": false
        };
        this.currentOrder.items[this.modifiers.currentItemIndex].modifiers.push(orderModifier);
      }
      const item = this.currentOrder.items[this.modifiers.currentItemIndex];
      this.currentOrder.items[this.modifiers.currentItemIndex].grand_total = parseFloat((item.subtotal + (await getModSum(item)) * item.quantity));

      this.countSubtotal();
      this.$forceUpdate();
    },
    hasAbilityToAddNewModifier (maxSelection, modifierGroup) {
      const countSelectedModifiers = this.countSelectedModifiers(modifierGroup);
      if (!maxSelection) return true;
      if (countSelectedModifiers >= maxSelection) {
        this.dispatchModifiersError(`You can only select up to ${maxSelection} modifiers for maximum`);
        return false;
      }

      return true;
    },
    countSelectedModifiers (modifierGroup) {
      const activeGroupModifiers = this.currentOrder.items[this.modifiers.currentItemIndex].modifiers
        .filter(mod => mod.group === modifierGroup.id && !mod.void_status);

      return activeGroupModifiers.length;
    },
    getProductModifierGroups: function (id) {
      const modifierGroups = this.products.find(item => item.id === id).modifier_groups;
      // Create a stable sort by using both sorting value and name as criteria
      modifierGroups.forEach(group => {
        group.modifiers.sort((a, b) => {
          // First sort by sorting value
          if (a.sorting !== b.sorting) {
            return a.sorting - b.sorting;
          }
          // If sorting values are the same, sort by name for stable order
          return a.name.localeCompare(b.name);
        });
      });
      
      // Sort the groups consistently
      modifierGroups.sort((a, b) => {
        if (a.sorting !== b.sorting) {
          return a.sorting - b.sorting;
        }
        return a.name.localeCompare(b.name);
      });
    
      return modifierGroups;
    },
    getModifiersByItemPosition: function (itemId) {
      const itemIndex = this.currentOrder.items.findIndex(item => item.id === itemId);
      const modGroups = [];
      const allModsAtItem = this.currentOrder.items[itemIndex].modifiers;
      const currentProductId = this.currentOrder.items[itemIndex].product;

      // find groups
      for (const mod of allModsAtItem) {
        if (!mod.void_status) {
          if (!modGroups.some(group => group.id === mod.group)) {
            const current_product = this.products.find(product => product.id.toString() === currentProductId.toString());
            if (!current_product) throw Error(`Product with id "${currentProductId}" is not available`);
            const group = current_product["modifier_groups"].find(modGroup => modGroup.id === mod.group);
            if (!group) throw Error(`Modifier group with id "${mod.group}" is not available locally`);
            modGroups.push({ id: mod.group, name: group.name, modifiers: [] });
          }
          modGroups.find(group => group.id === mod.group).modifiers.push({
            oryginal: mod.oryginal,
            name: mod.name,
            price: mod.price,
            price_before_tax: mod.price_before_tax
          });
        }
      }
      return modGroups;
    },
    dispatchModifiersError (info) {
      this.$store.dispatch("errorModal/displayModalError", {
        modalId: "modifier-error",
        errorBody: {
          title: "Modifiers",
          info
        }
      });
    },
    savePredefinedModifiers(modifiers, modifier_groups, itemIndex=0){
      // decalring modifier array
      let modifierArray = []
      // iterate through refund modifiers
      modifiers.forEach(modifier=>{

        const selectedModifier = modifier_groups[0].modifiers.find(selectedModifier=>selectedModifier.id === modifier.oryginal)
        // find the selected modifers from the modifier_groups' list and push them up the modifiersArray
        if(selectedModifier)
          modifierArray.push(selectedModifier)
      })
      
      // define the index of item having modifiers
      this.modifiers.currentItemIndex = itemIndex

      // iterate though all the modifiers in the array and insert into currentOrder's specific item
      modifierArray.forEach(modifier=>{
        this.toggleModifierForItem(modifier,modifier_groups[0])
        this.modifiersSave()
      })
      
    }
  }
};
