import * as qz from "qz-tray";
import { sha256 } from "js-sha256";
import * as moment from "moment";
import Printer from "./print/printer";
import { hextorstr, KEYUTIL, KJUR, stob64 } from "jsrsasign";
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import groupBy from "lodash.groupby";
import store from "../store/store";
import { sendToKds } from "./kds/kdsHelper";

const defaultWidthPercent = 0.95;

qz.api.setSha256Type(data => sha256(data));
qz.api.setPromiseType(resolver => new Promise(resolver));
qz.security.setCertificatePromise(function (resolve) {

  //Alternate method 2 - direct
  resolve(`-----BEGIN CERTIFICATE-----
MIIFCDCCAvCgAwIBAgIQNzkyMDI0MDcyMjIyNDYyNTANBgkqhkiG9w0BAQsFADCB
mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry
aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ
cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz
dHJpZXMuY29tMB4XDTI0MDcyMjIyNDYyNVoXDTI2MDcyMjIzMzAyNFowgcMxCzAJ
BgNVBAYMAlVTMRMwEQYDVQQIDApOZXcgSmVyc2V5MRAwDgYDVQQHDAdQYXJhbXVz
MQ0wCwYDVQQKDARCaXlvMQ0wCwYDVQQLDARCaXlvMQ0wCwYDVQQDDARCaXlvMSIw
IAYJKoZIhvcNAQkBDBNtYXR0QGJpeW93YWxsZXQuY29tMTwwOgYDVQQNDDNyZW5l
d2FsLW9mLTBhNTZjMGZlZmVjYTc2ZDRmYzAxODE4NGU0ODQ3MDQ3ZDBmZWVmMjMw
ggEgMAsGCSqGSIb3DQEBAQOCAQ8AMIIBCgKCAQEAmOP8o8t6hj2A52OAQ08GjV1S
t0E08f681dL1QBjnarDuOMPFlvbnziR8n1c4fmODLNPdmJ1+ZOliTpVDkbD9xNFj
CoxHkpPOiGY5glGLdcI8LMy356F4R6hw+MDY1KLMfWC4K4X4N8/6bwjEU/9oqrtE
+lPcPUN3RilveER2gbjQyAt62CH/acYY1BASUQj0Ba8dvYflTqp0DMYm5C4Y9uuP
h1sML1cI5i6SBBRxWvFM8UfgIgu5tA3v/QGRV8GiEXcBoEwzMiXrZGldEx+wi5Eo
fkaAhmBsZo0TDp5+tFDK31m7ecwFEOtab2j3qI5yzKJVyPYJn6mb5cw/sz+iBQID
AQABoyMwITAfBgNVHSMEGDAWgBSQplC3hNS56l/yBYQTeEXoqXVUXDANBgkqhkiG
9w0BAQsFAAOCAgEAAVk/PHeFv2zBtxTErjeGl/IOijUrA1ZaW51lHiL59t/4U8Yw
fxMiavCvFJPtQV1KM9ei6BM/iWfHWRLKz4b+gdFrcdjoUgtd223PBQxzTHifhgaE
GoM9RGumxLrQULF+tjTf1+mO4NmsUZW9e6gulxnJ1mVbEQpsbG4m8w/BMqLONqxc
uZOkOjDsbDrFCfvmAM4qLgaXNMy2mllPJ44zSYgmfeYokgu0Z0jIoaEQP8lVR1b4
xTLaoe825Rag6cFwKVVwQ7jYE6t3ALpa7BbF+L5icb2X3hwTJX4hrb08tgwAmIPl
eC+ZtaS7/oKAzIsP5KBfAI3Keagp6q8/ekMFLPaHg3/FtahpFiGHM8maTBXjqZB6
ykzbbzlB7W+JsvX5JyE+Hkf4tR/kf7GWVa2WViGrBmVEvtCFRlMA0h5+ogGSPi1Y
84YIn9P0tedpageM4c/QzETm8fX0c8pMGBOiuoQgJyRTckJ85y+0rmhI033go0xb
VWZG+JFkubr/nE6cm96nNaK8z7M3IS3lHboVXUoodeQF7jsXnWj9lVEAfV1wClpZ
O4lWqF69D9J+1Wqn9CiM+no1eppk36hTjZSnRi9BoVC4nTqwVOLG6bU4vRMyeHpo
fSF1waNQ6GQfB9SgjkyBwbaToYVuL0cK0dDW/WAf0LcloHzuE15UeJ7iuFE=
-----END CERTIFICATE-----
--START INTERMEDIATE CERT--
-----BEGIN CERTIFICATE-----
MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT
MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ
bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG
A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx
emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow
gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0
cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM
EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1
c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU
iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H
YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM
GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH
EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh
0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX
xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1
9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE
ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD
2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX
6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY
5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ
plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S
BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ
eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k
gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT
x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu
pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw
2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp
rbO7BnjW
-----END CERTIFICATE-----`);
});

const privateKey = "-----BEGIN PRIVATE KEY-----\n" +
  "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCY4/yjy3qGPYDn\n" +
  "Y4BDTwaNXVK3QTTx/rzV0vVAGOdqsO44w8WW9ufOJHyfVzh+Y4Ms092YnX5k6WJO\n" +
  "lUORsP3E0WMKjEeSk86IZjmCUYt1wjwszLfnoXhHqHD4wNjUosx9YLgrhfg3z/pv\n" +
  "CMRT/2iqu0T6U9w9Q3dGKW94RHaBuNDIC3rYIf9pxhjUEBJRCPQFrx29h+VOqnQM\n" +
  "xibkLhj264+HWwwvVwjmLpIEFHFa8UzxR+AiC7m0De/9AZFXwaIRdwGgTDMyJetk\n" +
  "aV0TH7CLkSh+RoCGYGxmjRMOnn60UMrfWbt5zAUQ61pvaPeojnLMolXI9gmfqZvl\n" +
  "zD+zP6IFAgMBAAECggEAF+M7JF5U4Od4He76acnNlMW5eD9cZdjuqRviTKb3dUB6\n" +
  "n+zakNk344no3L50aa7jnlvl2gP1bQ/X175Thc5Y/+jVKB89yYWFdeM+5gEWoIn9\n" +
  "++PqcerVD6EeMYdz+VkMSSSU8dvnKdU5ZCLDBfq4YECNKO10UjAZ0SEFNQ3BVEgN\n" +
  "p3Kva4LNXxm6IMppVSbKXoesig6bwAIETCjCpJJMtiBgjCvrsWJUk6y7a+q4doYg\n" +
  "Ixfc9OUkwdSd2kUQR82d3sgqBd8hIKAx5gbrzjPblpa2iAguiWGjZZUe/DFJ/+jA\n" +
  "hXkwA7PzvU99GPXmNqFUVenvkaDKGBIDndcv3hcGgQKBgQDII5pUC//u6GnPRW8y\n" +
  "lLCWhid6JtkMeyoNXITngWn1PhWDrS4vPZv0zD1dgE87iwT82Wuou8NcKT2RQ1Sz\n" +
  "oFCvUrQQl0Ne/3rqsC5RjWUBJHkVGTV3rXIkQ/W31iMExACRx4Mxpaqb+EyiwATL\n" +
  "pj6xit8x28G0s/kfLX3KyBFQwQKBgQDDkF66nLmmWkGRXDZkA/7fck2Y0d6kQhmt\n" +
  "DM7Vn1IQfES47AbjvKpEJBwnoqfjA7kxn63ps6iw+NKNlJaJ6nI+c46U1HP/JMWQ\n" +
  "4WmQYDLFcU/gH6hnGbRfOxEl6QJT/pH9j0BLOnLnSIRBiwojXtCjFjp1h2pdxkwg\n" +
  "q0kmuG5eRQKBgQCc31MzN6im8aMmJO2Q/v0fLw44b8THRK7khDVkZIrSAJTKfm7p\n" +
  "9meMzJ348P6p1EDXk/AP9QSe83BfsR/uH+W+VpvvOTFEgAovdGMJ50NZTuZdhEHl\n" +
  "iZ0jEeXC7+TI8nzjxzdtLcZ5tHkVRF895RPVA94IbZD6e+rz5itoS8NOwQKBgDn7\n" +
  "iQqdQzH+Wqhlhb7brGrFshoYR4G+E2zKely+Ew/OOHX3jQdivbzcU/iwckSNqw/6\n" +
  "fw7mdKKb85mEr6mOeHbpviGEaCwC1AzjuG/VBdiigwM5f62p4l/F3Iy37DfJJ7Hd\n" +
  "Ejv7xWurhYMFXWklSIjCI20LTE8nZxTclCDF1/tdAoGBAIMw0hzgjymoqq9oADQn\n" +
  "4DYp69LWHy1aoGfaBBAqNw222VHPt2D8vhFdzse+GwOP3tSPWReg6z8R/CjIunnw\n" +
  "C6WwTrYVOecmJBSYSZ6GWh/I57QDsNPtWKepP3Hs8YtePV4tyzADdFRiCizdfaf+\n" +
  "eW/C93o743kyB+xcHpMq4+ZB\n" +
  "-----END PRIVATE KEY-----";

qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
qz.security.setSignaturePromise(function (toSign) {
  return function (resolve, reject) {
    try {
      const pk = KEYUTIL.getKey(privateKey);
      const sig = new KJUR.crypto.Signature({ "alg": "SHA512withRSA" });  // Use "SHA1withRSA" for QZ Tray 2.0 and older
      sig.init(pk);
      sig.updateString(toSign);
      const hex = sig.sign();
      resolve(stob64(hextorstr(hex)));
    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
      reject(err);
    }
  };
});

async function groupingModifiers (productId, modifiers) {
  const groupedMods = [];
  for (const mod of modifiers) {
    const product = store.getters["config/product"](productId);
    if (!product) {
      const errorMsg = `Could not find product with id ${productId}, skipping`;
      console.error(errorMsg);
      Sentry.captureMessage(errorMsg);
      continue;
    }
    const group = product["modifier_groups"].find(sgroup => sgroup.id === mod.group);
    if (!group) {
      const errorMsg = `Could not find modifier group with id ${mod.group.id}, skipping`;
      console.error(errorMsg);
      Sentry.captureMessage(errorMsg);
    } else {
      if (!groupedMods.find(groupIter => groupIter.name === group.name)) {
        groupedMods.push({ name: group.name, modifiers: [] });
      }
      groupedMods.find(groupIter => groupIter.name === group.name).modifiers.push(mod);
    }

  }
  return groupedMods;
}

async function buildDateTimeCashierPart(printer, currentEmployee, tableID) {
  // defining localization variables 
  const localization = store.getters["config/isLocalization"]
  const localizedFormat = "MM/DD/YYYY"
  const deLocalizedFormat = "DD/MM/YYYY"

  // localizing date format if country is US
  const date = moment(new Date()).format(localization ? localizedFormat : deLocalizedFormat);
  const time = moment(new Date()).format("hh:mm A");
  const tableName = tableID ? (store.getters["config/getTableById"](tableID)).table_name || "unknown" : null;
  printer.tableCustom([
    { text: "Date: " + date, align: "LEFT", width: 1 }
  ]);
  printer.tableCustom([
    { text: "Time: " + time, align: "LEFT", width: 1 }
  ]);
  if (currentEmployee) {
    printer.tableCustom([
      {
        text: "Cashier: " + currentEmployee.name,
        align: "LEFT",
        width: 1
      }]);
  }
  if (tableName) {
    printer.tableCustom([
      {
        text: "Table: " + tableName,
        align: "LEFT",
        width: 1
      }]);
  }
  return printer;
}

async function buildFooter (printer) {
  printer.tableCustom([
    { text: "Powered by Biyo POS", align: "CENTER", width: 1 }
  ]);
  return printer;
}

async function isDiningOption () {
  const terminal = store.getters["config/terminal"];
  const printer_dining_option = terminal.db_settings.find(setting => setting.name === "printer_dining_option");
  return printer_dining_option ? printer_dining_option.value.toLowerCase() === "true" : true;
}

async function buildTaxesPart (printer, items) {
  let allTaxes = [];
  const currency = store.getters["config/currency"]
  items.filter(item => !item.void_status).forEach((item) => {
    if (item.tax_items) {
      const taxItemsToAdd = item.tax_items.map(function (entry) {
        entry.quantity = item.quantity;
        return entry;
      });
      allTaxes = allTaxes.concat(taxItemsToAdd);
    }
  });
  const byName = groupBy(allTaxes, it => it["name"]);
  const calculatedTax = Object.keys(byName).map(name => {
    const sum = byName[name].reduce((acc, it) => acc + it.tax_total * it.quantity, 0);
    return {
      tax_name: name,
      total: sum
    };
  });
  calculatedTax.forEach((tax) => {
    printer.tableCustom([
      { text: tax.tax_name, align: "LEFT", width: 0.50 },
      { text: currency + ' ' +tax.total.toFixed(2), align: "RIGHT", width: 0.45 }]);

  });

}

async function buildItemsPart (printer, items, kitchen = null, includeVoid = false) {
  const currency = store.getters["config/currency"]
  if (!kitchen) {
    printer
      .tableCustom([
        { text: "Qty", align: "LEFT", width: 0.10 },
        { text: "Item Name", align: "LEFT", width: 0.50 },
        { text: "Subtotal", align: "RIGHT", width: 0.35 }]);
  } else {
    printer
      .tableCustom([
        { text: "Qty", align: "LEFT", width: 0.2 },
        { text: "Item Name", align: "LEFT", width: 0.8 }]);
  }

  printer.line();
  printer.align("ct");
  for (const item of items) {
    if (!item.void_status || includeVoid) {
      printer.tableCustom([
        { text: item.quantity, align: "LEFT", width: 0.1 },
        { text: item.name, align: "LEFT", width: kitchen ? 0.8 : 0.5 },
        { text: kitchen ? "" : currency + ' ' + item.subtotal.toFixed(2), align: "RIGHT", width: kitchen ? 0 : 0.35 }
      ]);
      if (item.modifiers) {
        for (const modifierGroup of await groupingModifiers(item.product, item.modifiers)) {
          printer.tableCustom([
            { text: "", align: "LEFT", width: 0.2 },
            { text: "-" + modifierGroup.name, align: "LEFT", width: kitchen ? 0.8 : 0.5 },
            { text: "", align: "RIGHT", width: kitchen ? 0 : 0.3 }
          ]);
          for (const modifier of modifierGroup.modifiers) {
            if (!modifier.void_status) {
              let modInfo = modifier.name;
              if (!kitchen) {
                modInfo = modInfo + " (" + modifier.price.toFixed(2) + ")";
              }
              printer.tableCustom([
                { text: "", align: "LEFT", width: 0.2 },
                { text: "--" + modInfo, align: "LEFT", width: kitchen ? 0.8 : 0.5 },
                { text: "", align: "RIGHT", width: kitchen ? 0 : 0.3 }
              ]);
            }
          }
        }
      }
      if (item.notes) {
        printer.tableCustom([
          { text: "", align: "LEFT", width: 0.2 },
          { text: "NOTE: " + item.notes, align: "LEFT", width: kitchen ? 0.8 : 0.5 },
          { text: "", align: "RIGHT", width: kitchen ? 0 : 0.3 }
        ]);
      }
      if (kitchen) {
        printer.line();
      }
    }
  }
  return printer;
}

export async function printReceipt (orderObject, typeOfReceipt = null) {
  const terminalInfo = store.getters["config/terminal"];
  const storeInfo = store.getters["config/store"];
  const currentEmployee = store.getters["employee"];
  const printer = new Printer(!!terminalInfo.printer["is_58mm"]);
  const currency = store.getters["config/currency"]
  // Get db_settings for signature line, tip line, and tip suggestions
  const dbSettings = terminalInfo["db_settings"] ? JSON.parse(JSON.stringify(terminalInfo["db_settings"])) : [];
  const showSignatureLine = dbSettings.find(setting => setting.name === "receipt_signature_line")?.value === "true";
  const showTipLine = dbSettings.find(setting => setting.name === "receipt_tip_line")?.value === "true";
  const showTipSuggestions = dbSettings.find(setting => setting.name === "receipt_tip_suggest")?.value === "true";
  // const imagePrint


  printer.align("ct");
  printer.tableCustom([
    { text: storeInfo.name, align: "CENTER", width: defaultWidthPercent }
  ]);
  if (storeInfo.address) {
    printer.tableCustom([
      { text: storeInfo.address, align: "CENTER", width: defaultWidthPercent }
    ]);
  }
  if (storeInfo.address_2) {
    printer.tableCustom([
      { text: storeInfo.address_2, align: "CENTER", width: defaultWidthPercent }
    ]);
  }
  printer.tableCustom([
    { text: "Phone: " + storeInfo.phone, align: "CENTER", width: defaultWidthPercent }
  ]);
  if (storeInfo.fax) {
    printer.tableCustom([
      { text: "Fax: " + storeInfo.fax, align: "CENTER", width: defaultWidthPercent }
    ]);
  }
  if (storeInfo.email) {
    printer.tableCustom([
      { text: storeInfo.email, align: "CENTER", width: defaultWidthPercent }
    ]);
  }
  if (storeInfo.website) {
    printer.tableCustom([
      { text: storeInfo.website, align: "CENTER", width: defaultWidthPercent }
    ]);
  }
  if (typeOfReceipt === "refund") {
    printer.line();
    printer.tableCustom([
      { text: "*** REFUND RECEIPT ***", align: "CENTER", width: 1 }
    ]);
    printer.line();
  }
  printer.text("");
  if (storeInfo.receipt_header_text) {
    printer.line();
    printer.align("ct");
    // printer.text(storeInfo.receipt_header_text);
    printer.tableCustom([
      { text: storeInfo.receipt_header_text, align: "CENTER", width: 1 }
    ]);
    printer.line();
    printer.align("lt");
  }

  printer.align("ct");
  printer.tableCustom([
    {
      text: `Sales Receipt#: ${orderObject.id}${orderObject.table ? "/" + orderObject.table : ""}`,
      align: "LEFT",
      width: 1
    }]);
  if ((await isDiningOption()) && orderObject.dining_option_type && terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type)) {
    printer.tableCustom([
      {
        text: `Dining Option: ${terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type).name}`,
        align: "LEFT",
        width: 1
      }]);
  }

  await buildDateTimeCashierPart(printer, currentEmployee, orderObject.table);
  printer.text("");
  printer.align("ct");
  await buildItemsPart(printer, orderObject.items);
  printer.text("");
  // subtotal

  printer.align("ct");
  printer.tableCustom([
    { text: "Subtotal:", align: "LEFT", width: 0.30 },
    { text: currency + ' ' +orderObject.subtotal.toFixed(2), align: "RIGHT", width: 0.65 }]);
  printer.tableCustom([
    { text: "Order Discount:", align: "LEFT", width: 0.40 },
    { text: currency + ' ' +orderObject.discount_total.toFixed(2), align: "RIGHT", width: 0.55 }]);
  await buildTaxesPart(printer, orderObject.items);
  // printer.tableCustom([
  //   { text: 'Sales Tax:', align: 'LEFT', width: 0.55 },
  //   { text: orderObject.tax_total.toFixed(2), align: 'RIGHT', width: 0.45 }]);
  if(orderObject.surcharge_value && orderObject.surcharge_value > 0 )
  printer.tableCustom([
    { text: "Surcharge:", align: "LEFT", width: 0.30 },
    { text: currency + ' ' +orderObject.surcharge_value.toFixed(2), align: "RIGHT", width: 0.65 }]);
  printer.tableCustom([
    { text: "TOTAL:", align: "LEFT", width: 0.30 },
    { text: orderObject.surcharge_value ? currency + ' ' +(orderObject.grand_total+orderObject.surcharge_value).toFixed(2) : currency + ' ' +orderObject.grand_total.toFixed(2), align: "RIGHT", width: 0.65 }]);
  if (orderObject.payments.length > 0) {
    // Log payment data for debugging
    console.log('Payment data for receipt:', JSON.stringify(orderObject.payments));
    
    orderObject.payments.forEach(function (payment) {
      // Add a separator line before payment type
      printer.line("-");
      
      // Set alignment to center for payment section
      printer.align("ct");
      printer.tableCustom([
        { text: "Tendered:", align: "LEFT", width: 0.30 },
        { text: orderObject.surcharge_value ? currency +' '+ (payment.amount + orderObject.surcharge_value).toFixed(2) : currency +' '+ payment.amount.toFixed(2), align: "RIGHT", width: 0.65 }]
      );
      printer.tableCustom([
        { text: "Change:", align: "LEFT", width: 0.30 },
        { text: currency +' '+payment.change_amount.toFixed(2), align: "RIGHT", width: 0.65 }]
      );
      printer.tableCustom([
        { text: "Payment Type:", align: "LEFT", width: 0.35 },
        { text: payment.payment_type, align: "RIGHT", width: 0.60 }]
      );

      // Display credit card details if available (for Dejavoo or NABancard)
      console.log('Card last four check:', payment.card_lastfour);
      if (payment.card_lastfour) {
        printer.tableCustom([
          { text: "Card Number:", align: "LEFT", width: 0.30 },
          { text: `XXXX-XXXX-XXXX-${payment.card_lastfour}`, align: "RIGHT", width: 0.65 }]
        );
      }

      // Check if this is a credit card payment
      const isCreditCardPayment = payment.payment_type && 
        (payment.payment_type.toLowerCase().includes('credit') || 
         payment.payment_type.toLowerCase().includes('card') ||
         payment.payment_type.toLowerCase().includes('dejavoo') ||
         payment.payment_type.toLowerCase().includes('nabancard'));

      console.log('Is credit card payment:', isCreditCardPayment);
      console.log('Payment type:', payment.payment_type);
      console.log('Payment processor response:', payment.processor_response ? 'exists' : 'missing');

      // If this is a credit card payment, try to extract details from processor_response
      if (isCreditCardPayment && payment.processor_response) {
        try {
          // Try to parse the processor response
          const processorData = JSON.parse(payment.processor_response);
          console.log('Processor data parsed successfully');
          
          // Extract card details from processor response if they're not already in the payment object
          if (processorData.CardData) {
            // Display card type
            if (processorData.CardData.CardType && !payment.card_type) {
              printer.tableCustom([
                { text: "Card Type:", align: "LEFT", width: 0.30 },
                { text: processorData.CardData.CardType, align: "RIGHT", width: 0.65 }]
              );
            }
            
            // Display entry method
            if (processorData.CardData.EntryType && !payment.entry_type) {
              printer.tableCustom([
                { text: "Entry Method:", align: "LEFT", width: 0.35 },
                { text: processorData.CardData.EntryType, align: "RIGHT", width: 0.60 }]
              );
            }
          }
          
          // Extract transaction details
          if (processorData.AuthCode && !payment.auth_code) {
            printer.tableCustom([
              { text: "Auth Code:", align: "LEFT", width: 0.30 },
              { text: processorData.AuthCode, align: "RIGHT", width: 0.65 }]
            );
          }
          
          if (processorData.TransactionType && !payment.dejavoo_transaction_type) {
            printer.tableCustom([
              { text: "Transaction:", align: "LEFT", width: 0.35 },
              { text: processorData.TransactionType, align: "RIGHT", width: 0.60 }]
            );
          }
          
          if (processorData.GeneralResponse && processorData.GeneralResponse.DetailedMessage && !payment.approval_message) {
            printer.tableCustom([
              { text: "Approval:", align: "LEFT", width: 0.35 },
              { text: processorData.GeneralResponse.DetailedMessage, align: "RIGHT", width: 0.60 }]
            );
          }
          
          if (processorData.CardData && processorData.CardData.Name && !payment.cardholder_name) {
            printer.tableCustom([
              { text: "Cardholder:", align: "LEFT", width: 0.35 },
              { text: processorData.CardData.Name, align: "RIGHT", width: 0.60 }]
            );
          }
        } catch (e) {
          console.log('Error parsing processor response:', e);
        }
      }
      
      // Display additional Dejavoo payment details if available
      console.log('Card type check:', payment.card_type);
      if (payment.card_type) {
        printer.tableCustom([
          { text: "Card Type:", align: "LEFT", width: 0.30 },
          { text: payment.card_type, align: "RIGHT", width: 0.65 }]
        );
      }

      if (payment.entry_type) {
        printer.tableCustom([
          { text: "Entry Method:", align: "LEFT", width: 0.30 },
          { text: payment.entry_type, align: "RIGHT", width: 0.65 }]
        );
      }

      if (payment.auth_code) {
        printer.tableCustom([
          { text: "Auth Code:", align: "LEFT", width: 0.30 },
          { text: payment.auth_code, align: "RIGHT", width: 0.65 }]
        );
      }

      console.log('Transaction type check:', payment.dejavoo_transaction_type);
      if (payment.dejavoo_transaction_type) {
        printer.tableCustom([
          { text: "Transaction Type:", align: "LEFT", width: 0.30 },
          { text: payment.dejavoo_transaction_type, align: "RIGHT", width: 0.65 }]
        );
      }

      if (payment.approval_message) {
        printer.tableCustom([
          { text: "Approval:", align: "LEFT", width: 0.30 },
          { text: payment.approval_message, align: "RIGHT", width: 0.65 }]
        );
      }

      if (payment.reference_id) {
        printer.tableCustom([
          { text: "Reference ID:", align: "LEFT", width: 0.30 },
          { text: payment.reference_id, align: "RIGHT", width: 0.65 }]
        );
      }

      if (payment.cardholder_name) {
        printer.tableCustom([
          { text: "Cardholder:", align: "LEFT", width: 0.30 },
          { text: payment.cardholder_name, align: "RIGHT", width: 0.65 }]
        );
      }
      
      
      // Add tip line for credit card payments if enabled in settings
      if (showTipLine && isCreditCardPayment) {
        printer.text("");
        printer.tableCustom([
          { text: "TIP:", align: "LEFT", width: 0.35 },
          { text: "____________", align: "RIGHT", width: 0.60 }]
        );
        printer.text("");
        printer.tableCustom([
          { text: "TOTAL:", align: "LEFT", width: 0.35 },
          { text: "____________", align: "RIGHT", width: 0.60 }]
        );
        printer.text("");
      }

      // Add signature line for credit card payments if enabled in settings
      if (showSignatureLine && isCreditCardPayment) {
        printer.text("");
        printer.tableCustom([
          { text: "X", align: "LEFT", width: 1.0 }]
        );
        printer.line("");
        printer.align("ct");
        printer.text("SIGNATURE");
        printer.text("");
        printer.align("ct");
        printer.text("I agree to pay the above amount");
        printer.text("according to the card issuer agreement.");

        printer.text("");
      }

      // Add tip suggestions table for credit card payments if enabled in settings
      if (showTipSuggestions && isCreditCardPayment) {
        printer.text("");
        printer.align("ct");
        
        // Calculate tip suggestions based on payment amount
        // Use the actual payment amount (accounting for surcharge if present)
        const baseAmount = orderObject.surcharge_value ? 
          parseFloat(payment.amount) + parseFloat(orderObject.surcharge_value) : 
          parseFloat(payment.amount);
        
        // Only show tip suggestions if we have a valid amount
        if (baseAmount > 0) {
          // Tip percentages
          const tipPercentages = [15, 18, 20];
          
          // Header row
          printer.tableCustom([
            { text: "Tip", align: "LEFT", width: 0.30 },
            { text: "Amount", align: "CENTER", width: 0.35 },
            { text: "Total", align: "RIGHT", width: 0.30 }
          ]);
          
          // Print tip suggestions
          tipPercentages.forEach(percentage => {
            const tipAmount = Math.round(baseAmount * (percentage / 100) * 100) / 100;
            const totalWithTip = baseAmount + tipAmount;
            
            printer.tableCustom([
              { text: `${percentage}%`, align: "LEFT", width: 0.30 },
              { text: `${currency} ${tipAmount.toFixed(2)}`, align: "CENTER", width: 0.35 },
              { text: `${currency} ${totalWithTip.toFixed(2)}`, align: "RIGHT", width: 0.30 }
            ]);
          });
          
          printer.text("");
        }
      }

    });
  }

  if (orderObject["customer"]) {
    printer.line();
    printer.title("CUSTOMER INFORMATION");
    printer.line();
    if (orderObject["customer"].first_name) printer.text(`First Name: ${orderObject["customer"].first_name}`);
    if (orderObject["customer"].last_name) printer.text(`Last Name: ${orderObject["customer"].last_name}`);
    if (orderObject["customer"].phone) printer.text(`Phone: ${orderObject["customer"].phone}`);

    // adding customer address on receipts
    if(orderObject["customer"].default_address)
    {
      const defaultAddress = orderObject["customer"].defaultAddress
      printer.text(`Address: ${defaultAddress?.address_1}`)
      if(defaultAddress?.address_2)
        printer.text(`Appartment, Suite: ${defaultAddress?.address_2}`)
      if(defaultAddress?.country)
        printer.text(`Country: ${defaultAddress?.country}`)
      if(defaultAddress?.state)
        printer.text(`State: ${defaultAddress?.state}`)
      if(defaultAddress?.city)
        printer.text(`City: ${defaultAddress?.city}`)
      if(defaultAddress?.postal_code)
        printer.text(`Postal Code: ${defaultAddress?.postal_code}`)
    }
    // if (orderObject["customer"].address) printer.text(`Address: ${orderObject["customer"].address}`);
    // if (orderObject["customer"].city) printer.text(`City: ${orderObject["customer"].city}`);
    // if (orderObject["customer"].state) printer.text(`State: ${orderObject["customer"].state}`);
    // if (orderObject["customer"].zipcode) printer.text(`Zipcode: ${orderObject["customer"].zipcode}`);
    printer.text("");
    printer.text("");
  }
  if (storeInfo.receipt_footer_text) {
    printer.line();
    printer.align("ct");
    printer.tableCustom([
      { text: storeInfo.receipt_footer_text, align: "CENTER", width: 1 }
    ]);
    printer.line();
  }

  printer.align("ct");
  printer.barcode(orderObject.id, "CODE39", {});
  printer.text("");
  await buildFooter(printer);
  await printer.print();
}

function getPerPrinterObjects (orderObject, kitchenItems) {
  const kitchenObjects = [];
  kitchenItems.forEach(function (item) {
    item.printers.forEach(function (printer) {
      if (item.has_kitchen_updates || printer.is_kds) {
        if (printer.print_per_single_item) {
          // Create separate print object for each quantity
          for (let i = 0; i < item.quantity; i++) {
            const singleItem = { ...item, quantity: 1 };
            kitchenObjects.push({ 
              printerInfo: printer, 
              items: [singleItem] 
            });
          }
        } else {
          // Group items by printer
          if (!kitchenObjects.find(printerObj => printerObj.printerInfo.id === printer.id)) {
            kitchenObjects.push({ printerInfo: printer, items: [] });
          }
          kitchenObjects.find(printerObj => printerObj.printerInfo.id === printer.id).items.push(item);
        }
      }
    });
  });
  return kitchenObjects;
}

export async function printKitchenReceipts (orderObject, kitchenItems, reason = undefined) {
  const printerObjects = getPerPrinterObjects(orderObject, kitchenItems);
  const printResults = {
    errors: []
  };
  for (const obj of printerObjects) {
    try {
      if (!obj.printerInfo.is_kds) {
        await printKitchenReceipt(orderObject, obj, reason);
      } else {
        const orderGeneralInfo = {
          id: orderObject.id,
          related_terminal: orderObject.related_terminal,
          open_date: orderObject.open_date,
          updated_at: orderObject.updated_at,
          dining_option: orderObject.dining_option
        };
        await sendToKds(obj, obj.printerInfo, orderGeneralInfo, orderObject.receipt_kitchen_printed);
      }
    } catch (e) {
      console.error("Can not print to ", obj.printerInfo);
      Sentry.captureException(e);
      printResults.errors.push({
          error: e,
          objectToPrint: obj
        }
      );
    }
  }
  return printResults;
}

async function printKitchenLabelESCP(kitchenObj, orderObject, reason) {
  Vue.$log.warn(`Print kitchen label ${reason ? "(" + reason + "}" : "(no reason)"} to `, kitchenObj.printerInfo);
  
  const terminalInfo = store.getters["config/terminal"];
  const currentEmployee = store.getters.employee;
  const separator = '-'.repeat(20);
  
  // Check if zebra_label setting is enabled
  const zebraLabelSetting = terminalInfo.db_settings.find(setting => setting.name === "zebra_label");
  const useZebraLabel = zebraLabelSetting ? zebraLabelSetting.value.toLowerCase() === "true" : false;
  
  let data = [];
  
  if (useZebraLabel) {
    // Use ZPL language for Zebra printers
    data.push('^XA'); // Start ZPL format
    
    // Set initial position and font
    let yPosition = 50;
    const xPosition = 50;
    const lineHeight = 30;
    
    // Add dining option if available
    if (await isDiningOption() && orderObject.dining_option_type && terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type)) {
      const diningOption = terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type);
      data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FD${diningOption.name}^FS`);
      yPosition += lineHeight;
    }
    
    // Add separator line
    data.push(`^FO${xPosition},${yPosition}^GB350,3,3^FS`);
    yPosition += lineHeight;
    
    // Add customer information if available
    if ((orderObject.customer && (orderObject.customer.first_name || orderObject.customer.last_name)) || (orderObject.customer && orderObject.customer.phone)) {
      const customerName = orderObject.customer ? 
        `${orderObject.customer.first_name || ''} ${orderObject.customer.last_name || ''}`.trim() : '';
      const customerPhone = orderObject.customer ? orderObject.customer.phone || '' : '';
      
      if (customerName) {
        data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FD${customerName}^FS`);
        yPosition += lineHeight;
      }
      
      if (customerPhone) {
        data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FD${customerPhone}^FS`);
        yPosition += lineHeight;
      }
      
      if (customerName || customerPhone) {
        data.push(`^FO${xPosition},${yPosition}^GB350,3,3^FS`);
        yPosition += lineHeight;
      }
    }
    
    // Order info section
    data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FDOrder# ${orderObject.id}^FS`);
    yPosition += lineHeight;
    
    data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FDDate: ${moment().format('MM/DD/YYYY')}^FS`);
    yPosition += lineHeight;
    
    data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FDTime: ${moment().format('hh:mm A')}^FS`);
    yPosition += lineHeight;
    
    // Add separator line
    data.push(`^FO${xPosition},${yPosition}^GB350,3,3^FS`);
    yPosition += lineHeight;
    
    // Items section
    for (const item of kitchenObj.items) {
      // Item name with quantity
      data.push(`^FO${xPosition},${yPosition}^A0N,30,30^FD${item.quantity}x ${item.name}^FS`);
      yPosition += lineHeight;
      
      data.push(`^FO${xPosition},${yPosition}^GB350,3,3^FS`); // Separator after item name
      yPosition += lineHeight;
      
      // Add modifiers if any
      if (item.modifiers && item.modifiers.length) {
        const groupedMods = await groupingModifiers(item.product, item.modifiers);
        for (const group of groupedMods) {
          // Add modifier group name
          data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FD${group.name}:^FS`);
          yPosition += lineHeight;
          
          // Add modifiers in this group
          for (const mod of group.modifiers) {
            if (!mod.void_status) {
              data.push(`^FO${xPosition + 20},${yPosition}^A0N,25,25^FD-> ${mod.name}^FS`);
              yPosition += lineHeight;
            }
          }
        }
        
        data.push(`^FO${xPosition},${yPosition}^GB350,3,3^FS`); // Separator after modifiers
        yPosition += lineHeight;
      }
      
      // Add item notes if exists
      if (item.notes) {
        data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FDNotes:^FS`);
        yPosition += lineHeight;
        
        data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FD${item.notes}^FS`);
        yPosition += lineHeight;
        
        data.push(`^FO${xPosition},${yPosition}^GB350,3,3^FS`); // Separator after notes
        yPosition += lineHeight;
      }
      
      yPosition += 10; // Extra space between items
    }
    
    // Footer section
    data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FDCashier: ${currentEmployee ? currentEmployee.name : ''}^FS`);
    yPosition += lineHeight;
    
    data.push(`^FO${xPosition},${yPosition}^A0N,25,25^FDTerminal: ${terminalInfo.name || 'Biyo POS'}^FS`);
    yPosition += lineHeight;
    
    data.push('^XZ'); // End ZPL format
  } else {
    // Original ESC/P code for non-Zebra printers
    // Initialize ESC/P commands with portrait mode
    data = [
      '\x1B\x69\x61\x00\x1B\x40', // set printer to ESC/P mode and clear memory buffer
      '\x1B\x69\x4C\x00', // set portrait mode (0 for portrait, 1 for landscape)
      '\x1B\x55\x02\x1B\x33\x0F', // set margin and line feed values
      '\x1B\x6B\x0B\x1B\x58\x00\x3A\x00', // set font and font size
    ];

    // Add dining option if available
    if (await isDiningOption() && orderObject.dining_option_type && terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type)) {
      const diningOption = terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type);
      // Add spaces before and after dining option name for centering
      const padding = Math.floor((20 - diningOption.name.length) / 2);
      data.push(' '.repeat(padding) + diningOption.name);
      data.push('\x0A');
    }

    // First separator
    data.push(separator);
    data.push('\x0A');

    // Add customer information if available
    if ((orderObject.customer && (orderObject.customer.first_name || orderObject.customer.last_name)) || (orderObject.customer && orderObject.customer.phone)) {
      const customerName = orderObject.customer ? 
        `${orderObject.customer.first_name || ''} ${orderObject.customer.last_name || ''}`.trim() : '';
      const customerPhone = orderObject.customer ? orderObject.customer.phone || '' : '';
      
      if (customerName) {
        const padding = Math.floor((20 - customerName.length) / 2);
        data.push(' '.repeat(padding) + customerName);
        data.push('\x0A');
      }
      
      if (customerPhone) {
        const padding = Math.floor((20 - customerPhone.length) / 2);
        data.push(' '.repeat(padding) + customerPhone);
        data.push('\x0A');
      }
      
      if (customerName || customerPhone) {
        data.push(separator);
        data.push('\x0A');
      }
    }

    // Order info section
    data.push(`Order# ${orderObject.id}`);
    data.push('\x0A');
    data.push(`Date: ${moment().format('MM/DD/YYYY')}`);
    data.push('\x0A');
    data.push(`Time: ${moment().format('hh:mm A')}`);
    data.push('\x0A');

    // Second separator
    data.push(separator);
    data.push('\x0A');

    // Items section
    for (const item of kitchenObj.items) {
      // Item name with quantity
      data.push(`${item.quantity}x ${item.name}`);
      data.push('\x0A');
      data.push(separator); // Separator after item name

      // Add modifiers if any
      if (item.modifiers && item.modifiers.length) {
        data.push('\x0A'); // Extra space before modifiers
        const groupedMods = await groupingModifiers(item.product, item.modifiers);
        for (const group of groupedMods) {
          // Add modifier group name
          data.push(`${group.name}:`);
          data.push('\x0A');
          
          // Add modifiers in this group
          for (const mod of group.modifiers) {
            if (!mod.void_status) {
              data.push(`-> ${mod.name}`);
              data.push('\x0A');
            }
          }
        }
        data.push(separator); // Separator after modifiers
      }

      // Add item notes if exists
      if (item.notes) {
        data.push('\x0A'); // Extra space before notes
        data.push('Notes:');
        data.push('\x0A');
        data.push(item.notes);
        data.push('\x0A');
        data.push(separator); // Separator after notes
      }

      data.push('\x0A'); // Extra space between items
    }

    // Footer section
    data.push(`Cashier: ${currentEmployee ? currentEmployee.name : ''}`);
    data.push('\x0A');
    data.push(`Terminal: ${terminalInfo.name || 'Biyo POS'}`);
    data.push('\x0A');

    // Add form feed to print
    data.push('\x0C');
  }

  // Print using QZ Tray
  const config = qz.configs.create(kitchenObj.printerInfo.name);
  await qz.print(config, data).catch(function(e) { 
    console.error(e);
    Vue.$log.error('Error printing kitchen label:', e);
  });
}

async function printKitchenReceipt (orderObject, kitchenObj, reason = undefined) {
  if (kitchenObj.printerInfo.design_template === 'kitchen_label') {
    // Use ESC/P for kitchen labels
    return await printKitchenLabelESCP(kitchenObj, orderObject, reason);
  }

  // Original ESC/POS code for other kitchen receipts
  Vue.$log.warn(`Print kitchen receipt ${reason ? "(" + reason + "}" : "(no reason)"} to `, kitchenObj.printerInfo);
  let printer;
  if (kitchenObj.printerInfo.design_template === 'kitchen_label') {
    // Use 2.4 inch (61mm) width for kitchen labels
    printer = new Printer(false, true);
  } else {
    printer = new Printer();
  }
  const terminalInfo = store.getters["config/terminal"];
  printer.line();
  if (reason) {
    printer.tableCustom([
      { text: `!!! ${reason.toUpperCase()} !!!`, align: "CENTER", width: 1 }
    ]);
  } else {
    printer.tableCustom([
      { text: "!!! NEW ITEM !!!", align: "CENTER", width: 1 }
    ]);
  }

  printer.line();
  const currentEmployee = store.getters.employee;
  printer.align("ct");
  printer.tableCustom([
    {
      text: `Receipt#: ${orderObject.id}${orderObject.table ? "/" + orderObject.table : ""}`,
      align: "LEFT",
      width: 1
    }]);
  if ((await isDiningOption()) && orderObject.dining_option_type && terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type)) {
    printer.tableCustom([
      {
        text: `Dining Option: ${terminalInfo["dining_options"].find((option) => option.id === orderObject.dining_option_type).name}`,
        align: "CENTER",
        width: 1
      }]);
  }
  printer.text("");
  await buildDateTimeCashierPart(printer, currentEmployee, orderObject.table);
  printer.text("");
  if (reason == "item canceled") {
    await buildItemsPart(printer, kitchenObj.items, true, true);
  } else {
    await buildItemsPart(printer, kitchenObj.items, true);
  }

  printer.text("");
  printer.align("ct");
  await printer.print(kitchenObj.printerInfo);
  Vue.$log.debug("Printed =) ");
}

export async function printSummary (isSaleSummary = false, daySummary = null) {
  const terminalInfo = store.getters["config/terminal"];
  const printer = new Printer(!!terminalInfo.printer["is_58mm"]);
  const currentShift = store.getters["shift"];
  const reportType = isSaleSummary ? "s" : currentShift.shift_close_date ? "z" : "x";

  // title
  let title = "Sale Summary";
  if (reportType === "x") {
    title = "Shift X Report";
  } else if (reportType === "z") {
    title = "Shift Z Report";
    title = "Shift Z Report";
  }
  printer.title(title, true);
  printer.priceRow("", "");

  // STORE INFO
  const storeInfo = store.getters["config/store"];
  // defining localization variables 
  const localization = store.getters["config/isLocalization"]
  const localizedFormat = "MM/DD/YYYY hh:mm A"
  const deLocalizedFormat = "DD/MM/YYYY hh:mm A"
  printer
    .tableCustom([
      { text: `Store: ${storeInfo.name}`, align: "LEFT", width: 1 }]);
  if (!isSaleSummary) {
    printer
      .tableCustom([
        { text: `Terminal: ${terminalInfo.name}`, align: "LEFT", width: 1 }]);
  }
  printer.line();
  let start = moment(moment().format("YYYY-MM-DDT") + storeInfo.start_time);
  if (isSaleSummary) {
    const now = moment();
    if (now < start) {
      start = start.subtract(1, "days");
    }
    // localizing date format if country is US
    printer.priceRow("From", start.format(localization ? localizedFormat : deLocalizedFormat), false, [0.3, 0.7]);
    printer.priceRow("", "");
    // localizing date format if country is US
    printer.priceRow("To", now.format(localization ? localizedFormat : deLocalizedFormat), false, [0.3, 0.7]);

  } else {
    // SHIFT
    const emplOpen = store.getters["config/employeeById"](currentShift.open_shift_employee);
    printer
      .tableCustom([
        { text: "Shift opened:", align: "LEFT", width: 1 }]);
    // localizing date format if country is US
    const openDate = moment(Date.parse(currentShift.shift_open_date)).format(localization ? localizedFormat : deLocalizedFormat);
    printer
      .tableCustom([
        { text: emplOpen.name, align: "LEFT", width: 0.5 },
        { text: openDate, align: "RIGHT", width: 0.5 }
      ]);
    
    //printing line
    printer.line();

    // printing starting cash amount
    printer.tableCustom([
      { text: 'Cash Starting Amount', align: "LEFT", width: 1 },
      { text: `$ ${currentShift.opening_amount.toFixed(2)}`, align:"RIGHT", width: 1}
    ])

    switch (reportType) { 
      case 'x':
        printXReport(printer);
        break;
      case 'z':
        printZReport(printer)
        break;
      default:
        break;
    }

    /* <<<<<<<< PRIOR CODE >>>>>>>>
    if (reportType === "z") {
      const emplClosed = store.getters["config/employeeById"](currentShift.close_shift_employee);
      // localizing date format if country is US
      const closeDate = moment(Date.parse(currentShift.shift_close_date)).format(localization ? localizedFormat : deLocalizedFormat);
      printer.priceRow("", "");
      printer.priceRow("Shift closed:", "");

      printer
        .tableCustom([
          { text: emplClosed.name, align: "LEFT", width: 0.5 },
          { text: closeDate, align: "RIGHT", width: 0.5 }
        ]);
    }
    */
  }

  // CASH DRAWER
 /* if (!isSaleSummary) {

   <<<<<<<<< PRIOR CODE >>>>>>>>> 
    printer.line();
    printer
      .tableCustom([
        { text: "Cash Drawer", align: "CENTER", width: 1 }
      ]);

    printer.line();
    printer.priceRow("Opening Amount", currentShift.opening_amount.toFixed(2));
    printer.priceRow("Total Sales", currentShift.total_cashtenders.toFixed(2));
    // PAYMENTS
    let payments = [];
    if (currentShift.payment_types) {
      payments = currentShift.payment_types;
    }
    if (payments.length > 0) {
      printer.line();
      printer
        .tableCustom([
          { text: "Payments", align: "CENTER", width: 1 }
        ]);
      printer.line();
      payments.forEach(function (payment) {
        printer.priceRow(payment.name, payment.sum.toFixed(2));
      });
    }

    printer.line();
    const safeDrop = 0;
    const paidIn = 0;
    const paidOut = 0;
    // Expected amount = Opening amount + Cash Sales - cash returns - safe drop + payin - pay out
    const expectedAmount = currentShift.opening_amount + currentShift.total_cashtenders - currentShift.total_cashreturns
      - safeDrop + paidIn - paidOut;
    printer.priceRow("Expected Amount", expectedAmount.toFixed(2), true);
    if (reportType === "z") {
      printer.priceRow("Closing amount", currentShift.closing_amount.toFixed(2));
      const diff = currentShift.closing_amount - expectedAmount;
      printer.priceRow("Difference", diff.toFixed(2), true);
    }
  
  }*/
  // SALE SUMMARY
  if (isSaleSummary) {
    printer.line();
    printer.title("Sale Summary", false);
    printer.line();

    const saleSummary = {
      grossSales: 0,
      discounts: 0,
      taxes: 0,
      netSales: 0
    };

    const orders = store.getters["orders/getAllOrders"];
    for (const order of orders) {
      // todo check datetime filter
      if (moment(order.open_date).unix() > start.unix() > start.unix()) {
        saleSummary.discounts += order.discount_total;
        saleSummary.taxes += order.tax_total;
      }
    }
    printer.priceRow("Net sales", daySummary["sale_summary"].net_sales.toFixed(2), true);
    printer.priceRow("Refunds", daySummary["sale_summary"].refunds.toFixed(2), false);
    printer.priceRow("Discounts", daySummary["sale_summary"].discounts.toFixed(2), false);
    printer.priceRow("Taxes", daySummary["sale_summary"].taxes.toFixed(2), false);
    printer.priceRow("Gross Sales", daySummary["sale_summary"].gross_sales.toFixed(2), true);

    // PAYMENTS
    const payments = daySummary.payments;
    if (payments.length > 0) {
      printer.line();
      printer
        .tableCustom([
          { text: "Payments", align: "CENTER", width: 1 }
        ]);
      printer.line();
      payments.forEach(function (payment) {
        printer.priceRow(payment.name, payment.sum.toFixed(2));
      });
    }
  }

  // ORDERS
  const orders = isSaleSummary ? daySummary["orders_count"] : {};
  if (Object.keys(orders).length > 0) {
    printer.line();
    printer
      .tableCustom([
        { text: "Orders", align: "CENTER", width: 1 }
      ]);
    printer.line();
    for (const [key, value] of Object.entries(orders)) {
      printer.priceRow(key, value);
    }
  }

  // END
  printer.line();
  printer.title(`End of ${title}`, true);

  await printer.print();
  Vue.$log.debug("Printed =) ");
}

const printBreakdowns = (printer) => { 
  const currentShift = store.getters["shift"];

  // printing total net sales
  printer.priceRow('Total Net Sales',`$ ${currentShift.payment_breakdown.net_sales.toFixed(2)}`)

  //printing line
  printer.line();

  // printing sales breakdown header
  printer.tableCustom([
    {text:'Sales Breakdown', align:"CENTER", width: 1}
  ])

  //printing line
  printer.line();

  // printing sales breakdown
  const sales = currentShift.payment_breakdown.sales
  sales.forEach(sale => { 
    printer.priceRow(`${sale.payment_type}`,`$ ${sale.sum.toFixed(2)}`)
  })

  //printing line
  printer.line();

  // printing refunds breakdown header
  printer.tableCustom([
    {text:'Refunds Breakdown', align:"CENTER", width: 1}
  ])

  //printing line
  printer.line();
  
  // printing refunds breakdown
  const refunds = currentShift.payment_breakdown.refund
  refunds.forEach(refund => { 
    printer.priceRow(`${refund.payment_type}`,`- $ ${refund.sum.toFixed(2)}`)
  })


}

const printXReport = (printer) => {
  //printing line
  printer.line();

  // print total_net_sales and sales and refund breakdowns
  printBreakdowns(printer)

}

const printZReport = (printer) => { 
  const currentShift = store.getters["shift"];

  // report type z 
  printer.line();
  const safeDrop = 0;
  const paidIn = 0;
  const paidOut = 0;
  const expectedAmount = currentShift.opening_amount + currentShift.payment_breakdown.net_sales
    - safeDrop + paidIn - paidOut;
  printer.priceRow("Expected Cash Amount", `$ ${expectedAmount.toFixed(2)}`);
  printer.priceRow("Cash Closing Amount", `$ ${currentShift.closing_amount.toFixed(2)}`);
  //printing line
  printer.line();
  const diff = currentShift.closing_amount - expectedAmount;
  const isCashOver = diff > 0 ? true : false
  printer.priceRow(`Cash ${isCashOver ? 'is Over' : 'Difference'}`, `${isCashOver ? '' : '-'} $ ${isCashOver ? diff.toFixed(2) : (-1* diff.toFixed(2))}`);

  //printing line
  printer.line()

  // print total_net_sales and sales and refund breakdowns
  printBreakdowns(printer)
}

export async function openDrawer () {
  try {
    const printer = new Printer();
    printer.openDrawerCommand();
    await printer.send();
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }

}
