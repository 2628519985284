import DashboardService from "../../services/dashboard-service";
import indexDbService from "@/helpers/dbHelper"
import customerAddressHelper from '@/helpers/customer/customer'
import * as Sentry from "@sentry/vue";

const state = () => ({
  list: [],
  updating_instance: {
    local_id: null,
    remote_id: null
  },
  usaState:[],
  countries:[]
});

// getters
const getters = {
  getAllCustomers: (state) => state.list,
  countries: (state)=>state.countries,
  usaState: (state)=> state.usaState
};

// mutations
const mutations = {
  save (state, payload) {
    const now = (new Date).toISOString();
    payload = {
      ...payload, ...{
        created_at: payload.id ? payload.created_at : now,
        updated: true
      }
    };
    let existingIndex;
    if (payload.id && payload.id > 0) {
      existingIndex = state.list.findIndex(instance => instance.id === payload.id);
    }
    if ((existingIndex === undefined || existingIndex === -1) && payload.local_id) {
      existingIndex = state.list.findIndex(instance => instance.local_id === payload.local_id);
    }
    if ((existingIndex === undefined || existingIndex === -1)) {
      state.list.push(payload);
    } else {
      state.list = [...state.list.map((item, itemIndex) => itemIndex !== existingIndex ? item : { ...item, ...payload })];
    }
  },
  updateId (state, payload) {
    state.updating = {
      local_id: payload.local_id || payload.id,
      remote_id: payload.id
    };
    const objIndex = state.list.findIndex((obj => (obj.local_id === payload.local_id || obj.id === payload.id)));
    state.list[objIndex].id = payload.id;
    indexDbService.saveToDb('customers',state.list[objIndex]).then()

  },
  updateCustomers (state, payload) {
    // If this is the first page (page 1), reset the list
    if (payload.page === 1) {
      state.list = [];
    }

    if (payload && payload.results && payload.results.length) {
      payload.results.forEach(retrieved => {
        // check if exist locally
        const index = state.list.findIndex(item => (retrieved.id && item.id === retrieved.id) || (retrieved.local_id && (item.local_id === retrieved.local_id)));
        if (index === -1) {
          // new
          state.list.push(retrieved);
          indexDbService.saveToDb('customers',retrieved).then()
        } else {
          // save only when remote order is newer then local
          const retrievedDate = retrieved.updated_at ? new Date(retrieved.updated_at) : null;
          const localDate = state.list[index].updated_at ? new Date(state.list[index].updated_at) : null;
          if ((retrievedDate > localDate) || !localDate) {
            state.list[index] = { ...state.list[index], ...retrieved };
            indexDbService.saveToDb('customers',state.list[index]).then()
          } else {
            console.info(`Retrieved instance was not saved as has local changes, retrievedDate |${retrievedDate}| > localDate |${localDate}|`);
          }
        }
      });
    }
  },
  SET_USA_STATES:(state,states)=>state.usaState = states,
  SET_COUNTRIES: (state,countries)=>state.countries = countries
};

// actions
const actions = {
  upload: async ({ state, commit }, payload) => {
    if(Object.keys(payload).includes("default_address"))
    {
      delete payload.default_address
    }
    const retrieved = (await DashboardService.postCustomer(payload));
    const index = state.list.findIndex(instance => (instance.local_id === retrieved.local_id) || (instance.id === retrieved.id));
    state.list[index].updated = false;
    if(!state.list[index]?.id)
      commit("updateId", retrieved);
  },
  uploadUpdated: async ({ state, dispatch }) => {
    const listToUpload = state.list.filter(instance => instance.updated);
    for (const instance of listToUpload) {
      const index = state.list.findIndex(instanceFind => ((instanceFind.local_id === instance.local_id) || (instanceFind.id === instance.id)));
      try {
        await dispatch("upload", instance);
        state.list[index]["updated"] = false;
      } catch (e) {
        Sentry.captureException(e);
        console.error(`Could not upload ${instance.local_id || instance.id}`);
        console.error(e);
      }
    }
  },
  fetch: async ({ commit, dispatch }) => {
    await dispatch('fetchCountriesAndStates')
    const retrievedCustomers = (await DashboardService.getCustomers()).results;
    
    // move customers default address to the very top pf th address list whenever the list is being fetched
    retrievedCustomers.forEach(customer=>{
     customer.addresses =  customerAddressHelper.serializeCustomerAddressList(customer.addresses)
      if(customer.default_address){
        customer.default_address = customerAddressHelper.serializeCustomerDefaultAddress(customer.default_address)
        customer.addresses.unshift(customer.default_address)
      }
    })

    commit('updateCustomers', {
      results: retrievedCustomers,
      page: 1
    });
  },
  fetchCountriesAndStates: async ({commit})=>{
    let retrivedCountries = (await DashboardService.getCountries())
    let retrivedUSAStates = (await DashboardService.getUSAStates())
    retrivedCountries = Object.entries(retrivedCountries).map(([key,value])=>{
      return {'value':key, 'text':value}
    })

    retrivedUSAStates = retrivedUSAStates.map(state=>{
      return {value:state,text:state}
    })
    commit("SET_COUNTRIES",retrivedCountries)
    commit("SET_USA_STATES",retrivedUSAStates)
  },
  deleteAddress: async (_,addressId)=>{
    await DashboardService.deleteAddress(addressId)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
